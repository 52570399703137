import {Serializer} from '@webaker/package-utils';
import {ValidationError} from './validation-error';
import {InvalidError} from './invalid-error';
import {ValidationRule} from './validation-rule';
import {createValidationErrorsBag, ValidationErrorsBag} from './validation-errors-bag';

const UNSERIALIZABLE_STRING: string = 'Unserializable';

export interface Validator {
    validate: <T = unknown>(item: unknown, rule: ValidationRule<T>) => item is T;
    assert: <T = unknown>(item: unknown, rule: ValidationRule<T>) => asserts item is T;
    errors: <T = unknown>(item: unknown, rule: ValidationRule<T>) => ValidationErrorsBag;
}

export interface ValidatorDeps {
    serializer: Serializer;
}

export function createValidator({serializer}: ValidatorDeps): Validator {

    const validate = <T = unknown>(item: unknown, rule: ValidationRule<T>): item is T => {
        const errors: ValidationError[] = rule(item);
        return errors.length === 0;
    };

    const assert = <T = unknown>(item: unknown, rule: ValidationRule<T>): asserts item is T => {
        const errors: ValidationError[] = rule(item);
        if (errors.length > 0) {
            const serializedItem: string = serializer.serialize(item) || UNSERIALIZABLE_STRING;
            const serializedErrors: string = serializer.serialize(errors) || UNSERIALIZABLE_STRING;
            throw new InvalidError(`Validation failed\n\nItem:\n${serializedItem}\n\nErrors:\n${serializedErrors}`, {item, errors});
        }
    };

    const errors = <T = unknown>(item: unknown, rule: ValidationRule<T>): ValidationErrorsBag => {
        const errors: ValidationError[] = rule(item);
        return createValidationErrorsBag(errors);
    };

    return {
        validate,
        assert,
        errors
    };

}