import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type SelectBoxClassName = 'selectBox' | 'clearButton' | 'arrowButton';

export interface SelectBoxCSSProps extends CSSThemeProps {

}

export function SelectBoxCSS({theme}: SelectBoxCSSProps) {
    return css<SelectBoxClassName>`

        .selectBox {
    
        }
        
        .clearButton {
            color: ${theme.color.danger} !important;
            padding: ${theme.spacing.small} !important;
        }

        .arrowButton {

        }

    `;
}