import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export interface CheckBoxCSSProps extends CSSThemeProps {

}

export function CheckBoxCSS({theme}: CheckBoxCSSProps) {

    return css`

        .checkBox {
            border-radius: ${theme.roundness.medium};
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: ${theme.spacing.medium};
            font-weight: bold;
            width: 2.25em;
            height: 2.25em;
            font-size: ${theme.fontSize.large};
            gap: ${theme.spacing.medium};
            transition: background-color ${theme.duration.short}, filter ${theme.duration.short};
            background-color: ${theme.color.white};
            color: ${theme.color.black};
            border: 1px solid ${theme.color.light};

            &:hover:not(:disabled):not(:active) {
                border-color: ${theme.color.gray};
            }

            &:disabled {
                filter: saturate(0.1) contrast(0.2) brightness(1.5);
                cursor: default;
            }
        }

    `;
}