import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type FilePickerClassName = 'filePicker' | 'clearButton' | 'uploadButton';

export interface FilePickerCSSProps extends CSSThemeProps {

}

export function FilePickerCSS({theme}: FilePickerCSSProps) {
    return css<FilePickerClassName>`

        .filePicker {
    
        }
        
        .clearButton {
            color: ${theme.color.danger} !important;
            padding: ${theme.spacing.small} !important;
        }
        
        .uploadButton {
            
        }

    `;
}