import {CSSThemeColor, useThemedCSSBundle} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {MutableRefObject, ReactElement, ReactNode, useCallback} from 'react';
import {ButtonCSS, ButtonPrivateCSS} from './button-css';

export interface ButtonProps {
    children?: ReactNode;
    onClick?: () => void;
    icon?: ReactElement;
    style?: ButtonStyle;
    color?: CSSThemeColor;
    active?: boolean;
    disabled?: boolean;
    className?: string;
    elementRef?: MutableRefObject<HTMLElement | null>;
}

export type ButtonStyle = 'text' | 'solid' | 'outline' | 'input';

export function Button({
    children,
    onClick,
    icon,
    style = 'text',
    color = 'dark',
    active,
    disabled,
    className,
    elementRef
}: ButtonProps) {

    const css = useThemedCSSBundle([
        ButtonCSS,
        ButtonPrivateCSS
    ], {
        color
    });

    const handleClick = useCallback(() => {
        onClick?.();
    }, [onClick]);

    return (
        <button ref={elementRef as any}
                onClick={handleClick}
                disabled={disabled}
                className={mergeClassNames(
                    css['button'],
                    css[`style-${style}`],
                    active && css['is-active'],
                    className
                )}>
            {icon && (
                <span className={css['icon']}>
                    {icon}
                </span>
            )}
            {children && (
                <span className={css['label']}>
                    {children}
                </span>
            )}
        </button>
    );

}