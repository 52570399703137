import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';
import {Corner} from '@webaker/package-utils';

export type InputClassName = 'input' | 'nativeInput' | 'icon' | 'is-disabled' | 'is-invalid' | 'outside' | `outside-${Corner}`;

export interface InputCSSProps extends CSSThemeProps {

}

export function InputCSS({theme}: InputCSSProps) {
    return css<InputClassName>`

        .input {
            background-color: ${theme.color.white};
            border: 1px solid ${theme.color.light};
            border-radius: ${theme.roundness.medium};
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            width: 100%;
            position: relative;
            height: 2.25em;

            &:hover:not(.is-disabled, .is-invalid) {
                border-color: ${theme.color.gray};
            }

            &:not(.is-disabled, .is-invalid):has(input:focus) {
                border-color: ${theme.color.gray};
                outline: none;
            }

            &.is-disabled {
                background-color: ${theme.color.veryLight};
            }

            &.is-invalid {
                border-color: ${theme.color.danger};
            }

            :where(button) {
                background: none;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: ${theme.fontSize.medium};
                color: ${theme.color.black};
                border: none;
                padding: 0 ${theme.spacing.medium};
                cursor: pointer;

                &:disabled {
                    color: ${theme.color.gray};
                    cursor: default;
                }
            }
        }

        .nativeInput {
            padding: 0 ${theme.spacing.medium};
            background: none;
            border: none;
            color: inherit;
            outline: none;
            width: 100%;
            height: 100%;
            text-overflow: ellipsis;
            flex: 1;

            &:read-only {
                cursor: default;
            }

            &::placeholder {
                color: ${theme.color.light};
            }
        }

        .icon {
            padding-left: ${theme.spacing.medium};
            display: flex;
            align-items: center;
        }

        .outside {
            position: absolute;
            min-width: 100%;
        }

        .outside-top-left {
            left: 0;
            bottom: 100%;
        }

        .outside-top-right {
            right: 0;
            bottom: 100%;
        }

        .outside-bottom-left {
            left: 0;
            top: 100%;
        }

        .outside-bottom-right {
            right: 0;
            top: 100%;
        }

    `;
}