import {ComponentRegistry} from '@webaker/app';
import {Provider} from '@webaker/package-deps';
import {UserRole} from '@webaker/package-user';
import {createAutoLoginOptions} from '../auto-login-options';

export interface ProvidedAdminUserClientDeps {

}

export interface RequiredAdminUserClientDeps {
    componentRegistry: ComponentRegistry;
}

export interface AdminUserClientDeps extends ProvidedAdminUserClientDeps, RequiredAdminUserClientDeps {

}

export interface AdminUserClientConfig {
    adminRoleId: UserRole['id'];
}

export type AdminUserClientProvider = Provider<AdminUserClientDeps, AdminUserClientConfig>;

export function createAdminUserClientProvider(): AdminUserClientProvider {
    return {

        registerServices: async ({resolve}) => {
            const componentRegistry = resolve('componentRegistry');
            const autoLoginOptions = createAutoLoginOptions();
            componentRegistry.registerComponentType(autoLoginOptions);
        }

    };
}