import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type IconItemClassName = 'iconItem' | 'icon' | 'info' | 'title' | 'subtitle' | 'is-inline' | 'is-clickable';

export function IconItemCSS({theme}: CSSThemeProps) {
    return css<IconItemClassName>`

        .iconItem {
            margin: ${theme.spacing.medium} 0;
            display: flex;
            text-align: left;
            width: 100%;
            flex-direction: row;
            align-items: center;
            vertical-align: top;
        }

        .icon {
            font-size: 2.5em;
            margin-right: ${theme.spacing.medium};
        }

        .iconList:hover .icon {
            color: ${theme.color.info};
        }

        .info {
            padding: ${theme.spacing.medium};
        }

        .title {
            margin: ${theme.spacing.small} 0;
        }

        .subtitle {
            font-size: ${theme.fontSize.small};
            opacity: 0.8;
        }

        .is-inline {
            margin: 0 0 ${theme.spacing.large};
            display: inline-flex;
            text-align: center;
            flex-direction: column;
            min-width: 4em;
        }

        .is-inline .icon {
            margin-right: 0;
            width: 100%;
        }

        .is-clickable {
            cursor: pointer;
        }

    `;
}