import {IdGenerator} from '@webaker/package-utils';
import {Api} from '../api';
import {API_FILE_PROPERTY, CONTENT_TYPE_HEADER, JSON_CONTENT_TYPE} from '../api-config';
import {ApiMethodName} from '../api-method';
import {ApiRequest} from '../api-request';
import {ApiResponse} from '../api-response';

export interface ApiClientRunner {
    makeApiRequest: <A extends Api = any, M extends ApiMethodName<A> = ApiMethodName<A>>(request: ApiRequest<A, M>) => Promise<ApiResponse<A, M>>;
    makeBatchApiRequest: (requests: ApiRequest[]) => Promise<ApiResponse[]>;
}

export interface ApiClientRunnerDeps {
    idGenerator: IdGenerator;
    fetch: typeof fetch;
}

export interface ApiClientRunnerConfig {
    apiRoute: string;
}

export function createApiClientRunner(
    {idGenerator, fetch}: ApiClientRunnerDeps,
    {apiRoute}: ApiClientRunnerConfig
): ApiClientRunner {

    const makeApiRequest = async <A extends Api = any, M extends ApiMethodName<A> = ApiMethodName<A>>(request: ApiRequest<A, M>): Promise<ApiResponse<A, M>> => {
        return await makeRequest('POST', `${apiRoute}/${request.name}/${request.method}`, request.params);
    };

    const makeBatchApiRequest = async (requests: ApiRequest[]): Promise<ApiResponse[]> => {
        if (!requests.length) {
            return [];
        }
        return await makeRequest('POST', apiRoute, requests);
    };

    const makeRequest = async <R = any>(method: string, url: string, body?: any): Promise<R> => {
        try {
            const preparedBody = prepareRequestBody(body);
            const isJSON = typeof preparedBody === 'string';
            return (await fetch(url, {
                method: method,
                body: preparedBody,
                headers: isJSON ? {
                    [CONTENT_TYPE_HEADER]: JSON_CONTENT_TYPE
                } : {}
            })).json();
        } catch {
            throw new Error(`Unknown request error`);
        }
    };

    const prepareRequestBody = (request?: any): string | FormData => {
        if (request) {
            const [data, blobs] = prepareDataAndBlobs(request);
            if (Object.keys(blobs).length) {
                const formData = new FormData();
                Object.entries(data).forEach(([key, value]) => {
                    formData.append(key, JSON.stringify(value));
                });
                Object.entries(blobs).forEach(([hash, blob]) => {
                    formData.append(hash, blob);
                });
                return formData;
            }
        }
        return JSON.stringify(request);
    };

    const prepareDataAndBlobs = (request: any): [any, Record<string, Blob>] => {
        if (request && typeof request === 'object') {
            const data: any = {};
            const blobs: Record<string, Blob> = {};
            for (const key in request) {
                if (request[key] instanceof Blob) {
                    const fileHash: string = idGenerator.generateId();
                    blobs[fileHash] = request[key];
                    data[key] = {[API_FILE_PROPERTY]: fileHash};
                } else {
                    const [nestedData, nestedBlobs] = prepareDataAndBlobs(request[key]);
                    data[key] = nestedData;
                    Object.assign(blobs, nestedBlobs);
                }
            }
            return [data, blobs];
        }
        return [request, {}];
    };

    return {
        makeApiRequest,
        makeBatchApiRequest
    };

}