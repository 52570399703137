import {ApiClientFactory} from '@webaker/package-api/client';
import {USER_API_NAME, UserApi} from '../user-api';

export interface UserClientApiDeps {
    apiClientFactory: ApiClientFactory;
}

export function createUserClientApi({apiClientFactory}: UserClientApiDeps): UserApi {

    return apiClientFactory.createApi<UserApi>(USER_API_NAME, {
        getLoggedUser: [],
        loginUserByCredentials: ['email', 'password'],
        logoutUser: []
    });

}