import {GetterParams, SetterParams, Store, StoreFactory} from '@webaker/package-store';
import {Component} from './component/component';
import {ComponentContent} from './content/component-content';
import {ComponentsTree} from './content/components-tree';
import {PageContent} from './content/page-content';
import {PageContentMutator} from './content/page-content-mutator';
import {PageContentSelector} from './content/page-content-selector';
import {Page} from './page/page';

export const APP_STORE_NAME = 'app';

export interface AppStoreState {
    pending: string[];
    page: Page | null;
    tree: ComponentsTree;
}

export interface AppStore extends Store<AppStoreState> {

    name: typeof APP_STORE_NAME;

    isPending: (key?: string) => boolean;
    startPending: (key: string) => void;
    finishPending: (key: string) => void;

    getPage: () => Page | null;
    getPageContent: () => PageContent | null;
    openPage: (pageContent: PageContent) => void;
    updatePage: (page: Page) => void;

    getComponents: () => Component[];
    getComponentById: (componentId: Component['id']) => Component | null;
    getComponentsByIds: (componentsIds: Component['id'][]) => Component[];
    getComponentsByType: <C extends Component = Component>(componentType: C['type']) => C[];
    getRootComponent: () => Component | null;
    getParentComponent: (componentId: Component['id']) => Component | null;
    getAncestorComponents: (componentId: Component['id']) => Component[];
    getChildComponents: (componentId: Component['id']) => Component[];
    getChildComponentsByType: <C extends Component = Component>(componentId: Component['id'], componentType: C['type']) => C[];
    getDescendantComponents: (componentId: Component['id']) => Component[];
    getDescendantComponentsByType: <C extends Component = Component>(componentId: Component['id'], componentType: C['type']) => C[];
    getPreviousComponent: (componentId: Component['id']) => Component | null;
    getNextComponent: (componentId: Component['id']) => Component | null;
    getComponentContent: (componentId: Component['id']) => ComponentContent | null;
    addComponent: <C extends Component = Component>(component: C) => void;
    updateComponent: <C extends Component = Component>(component: C) => void;
    deleteComponent: (componentId: Component['id']) => void;
    moveComponent: (componentId: Component['id'], targetComponentId: Component['id']) => void;
    moveComponentBefore: (componentId: Component['id'], targetComponentId: Component['id']) => void;
    moveComponentAfter: (componentId: Component['id'], targetComponentId: Component['id']) => void;
    moveComponentToRoot: (componentId: Component['id']) => void;
    addComponentContent: (componentContent: ComponentContent) => void;

    getSharedComponents: () => Component[];
    getSharedComponentsContents: () => ComponentContent[];
    getSharedAncestorComponent: (componentId: Component['id']) => Component | null;
    shareComponent: (componentId: Component['id']) => void;
    unshareComponent: (componentId: Component['id']) => void;
    attachComponentContent: (componentId: Component['id']) => void;
    detachComponentContent: (componentId: Component['id']) => void;

}

export interface AppStoreDeps {
    pageContentMutator: PageContentMutator;
    pageContentSelector: PageContentSelector;
    storeFactory: StoreFactory;
}

export function createAppStore({
    pageContentMutator,
    pageContentSelector,
    storeFactory
}: AppStoreDeps): AppStore {

    const getPageContent = (state: AppStoreState): PageContent | null => {
        return state.page ? {
            page: state.page,
            tree: state.tree
        } : null;
    };

    return storeFactory.createStore<AppStore>({

        name: APP_STORE_NAME,

        state: {
            pending: [],
            page: null,
            tree: {
                components: [],
                hooks: []
            }
        },

        getters: {

            isPending: ({state}, key?: string): boolean => {
                if (!key) {
                    return state.pending.length > 0;
                }
                return state.pending.includes(key);
            },

            getPage: ({state}): Page | null => {
                const pageContent = getPageContent(state);
                return pageContent ? pageContentSelector.getPage(pageContent) : null;
            },

            getPageContent: ({state, memo}): PageContent | null => {
                return memo(() => {
                    return getPageContent(state);
                }, [state.page, state.tree]);
            },

            getComponents: ({state, memo}): Component[] => {
                return memo(() => {
                    const pageContent = getPageContent(state);
                    return pageContent ? pageContentSelector.getComponents(pageContent) : [];
                }, [state.tree.components]);
            },

            getComponentById: ({state, memo}, id: Component['id']): Component | null => {
                return memo(() => {
                    const pageContent = getPageContent(state);
                    return pageContent ? pageContentSelector.getComponentById(pageContent, id) : null;
                }, [state.tree.components], {id});
            },

            getComponentsByIds: ({state, memo}, ids: Component['id'][]): Component[] => {
                return memo(() => {
                    const pageContent = getPageContent(state);
                    return pageContent ? pageContentSelector.getComponentsByIds(pageContent, ids) : [];
                }, [state.tree.components], {ids});
            },

            getComponentsByType: <C extends Component = Component>({state, memo}: GetterParams<AppStoreState>, type: C['type']): C[] => {
                return memo(() => {
                    const pageContent = getPageContent(state);
                    return pageContent ? pageContentSelector.getComponentsByType(pageContent, type) : [];
                }, [state.tree.components], {type});
            },

            getRootComponent: ({state, memo}): Component | null => {
                return memo(() => {
                    const pageContent = getPageContent(state);
                    return pageContent ? pageContentSelector.getRootComponent(pageContent) : null;
                }, [state.page, state.tree]);
            },

            getParentComponent: ({state, memo}, componentId: Component['id']): Component | null => {
                return memo(() => {
                    const pageContent = getPageContent(state);
                    return pageContent ? pageContentSelector.getParentComponent(pageContent, componentId) : null;
                }, [state.page, state.tree], {componentId});
            },

            getAncestorComponents: ({state, memo}, componentId: Component['id']): Component[] => {
                return memo(() => {
                    const pageContent = getPageContent(state);
                    return pageContent ? pageContentSelector.getAncestorComponents(pageContent, componentId) : [];
                }, [state.page, state.tree], {componentId});
            },

            getChildComponents: ({state, memo}, componentId: Component['id']): Component[] => {
                return memo(() => {
                    const pageContent = getPageContent(state);
                    return pageContent ? pageContentSelector.getChildComponents(pageContent, componentId) : [];
                }, [state.page, state.tree], {componentId});
            },

            getChildComponentsByType: <C extends Component = Component>(
                {state, memo}: GetterParams<AppStoreState>,
                componentId: Component['id'],
                componentType: C['type']
            ): C[] => {
                return memo(() => {
                    const pageContent = getPageContent(state);
                    return pageContent ? pageContentSelector.getChildComponentsByType<C>(pageContent, componentId, componentType) : null;
                }, [state.page, state.tree], {componentId, componentType});
            },

            getDescendantComponents: ({state, memo}, componentId: Component['id']): Component[] => {
                return memo(() => {
                    const pageContent = getPageContent(state);
                    return pageContent ? pageContentSelector.getDescendantComponents(pageContent, componentId) : [];
                }, [state.page, state.tree], {componentId});
            },

            getDescendantComponentsByType: <C extends Component = Component>(
                {state, memo}: GetterParams<AppStoreState>,
                componentId: Component['id'],
                componentType: C['type']
            ): C[] => {
                return memo(() => {
                    const pageContent = getPageContent(state);
                    return pageContent ? pageContentSelector.getDescendantComponentsByType<C>(pageContent, componentId, componentType) : null;
                }, [state.page, state.tree], {componentId, componentType});
            },

            getPreviousComponent: ({state, memo}, componentId: Component['id']): Component | null => {
                return memo(() => {
                    const pageContent = getPageContent(state);
                    return pageContent ? pageContentSelector.getPreviousComponent(pageContent, componentId) : null;
                }, [state.page, state.tree], {componentId});
            },

            getNextComponent: ({state, memo}, componentId: Component['id']): Component | null => {
                return memo(() => {
                    const pageContent = getPageContent(state);
                    return pageContent ? pageContentSelector.getNextComponent(pageContent, componentId) : null;
                }, [state.page, state.tree], {componentId});
            },

            getComponentContent: ({state, memo}, componentId: Component['id']): ComponentContent | null => {
                return memo(() => {
                    const pageContent = getPageContent(state);
                    return pageContent ? pageContentSelector.getComponentContent(pageContent, componentId) : null;
                }, [state.page, state.tree], {componentId});
            },

            getSharedComponents: ({state, memo}): Component[] => {
                return memo(() => {
                    const pageContent = getPageContent(state);
                    return pageContent ? pageContentSelector.getSharedComponents(pageContent) : [];
                }, [state.tree.components]);
            },

            getSharedComponentsContents: ({state, memo}): ComponentContent[] => {
                return memo(() => {
                    const pageContent = getPageContent(state);
                    return pageContent ? pageContentSelector.getSharedComponentsContents(pageContent) : [];
                }, [state.tree]);
            },

            getSharedAncestorComponent: ({state, memo}, componentId: Component['id']): Component | null => {
                return memo(() => {
                    const pageContent = getPageContent(state);
                    return pageContent ? pageContentSelector.getSharedAncestorComponent(pageContent, componentId) : null;
                }, [state.tree], {componentId});
            }

        },

        setters: {

            startPending: ({state}, key: string): AppStoreState => {
                if (state.pending.includes(key)) {
                    return state;
                }
                return {
                    ...state,
                    pending: [
                        ...state.pending,
                        key
                    ]
                };
            },

            finishPending: ({state}, key: string): AppStoreState => {
                if (!state.pending.includes(key)) {
                    return state;
                }
                return {
                    ...state,
                    pending: state.pending.filter((stateKey: string): boolean => {
                        return stateKey !== key;
                    })
                };
            },

            openPage: ({state}, pageContent: PageContent): AppStoreState => {
                return {
                    ...state,
                    page: pageContent.page,
                    tree: pageContent.tree
                };
            },

            updatePage: ({state}, page: Page): AppStoreState => {
                const pageContent = getPageContent(state);
                return pageContent ? {
                    ...state,
                    ...pageContentMutator.updatePage(pageContent, page)
                } : state;
            },

            addComponent: <C extends Component = Component>({state}: SetterParams<AppStoreState>, component: C): AppStoreState => {
                const pageContent = getPageContent(state);
                return pageContent ? {
                    ...state,
                    ...pageContentMutator.addComponent<C>(pageContent, component)
                } : state;
            },

            updateComponent: <C extends Component = Component>({state}: SetterParams<AppStoreState>, component: C): AppStoreState => {
                const pageContent = getPageContent(state);
                return pageContent ? {
                    ...state,
                    ...pageContentMutator.updateComponent<C>(pageContent, component)
                } : state;
            },

            deleteComponent: ({state}, componentId: Component['id']): AppStoreState => {
                const pageContent = getPageContent(state);
                return pageContent ? {
                    ...state,
                    ...pageContentMutator.deleteComponent(pageContent, componentId)
                } : state;
            },

            moveComponent: ({state}, componentId: Component['id'], targetComponentId: Component['id']): AppStoreState => {
                const pageContent = getPageContent(state);
                return pageContent ? {
                    ...state,
                    ...pageContentMutator.moveComponent(pageContent, componentId, targetComponentId)
                } : state;
            },

            moveComponentBefore: ({state}, componentId: Component['id'], targetComponentId: Component['id']): AppStoreState => {
                const pageContent = getPageContent(state);
                return pageContent ? {
                    ...state,
                    ...pageContentMutator.moveComponentBefore(pageContent, componentId, targetComponentId)
                } : state;
            },

            moveComponentAfter: ({state}, componentId: Component['id'], targetComponentId: Component['id']): AppStoreState => {
                const pageContent = getPageContent(state);
                return pageContent ? {
                    ...state,
                    ...pageContentMutator.moveComponentAfter(pageContent, componentId, targetComponentId)
                } : state;
            },

            moveComponentToRoot: ({state}, componentId: Component['id']): AppStoreState => {
                const pageContent = getPageContent(state);
                return pageContent ? {
                    ...state,
                    ...pageContentMutator.moveComponentToRoot(pageContent, componentId)
                } : state;
            },

            addComponentContent: ({state}, componentContent: ComponentContent): AppStoreState => {
                const pageContent = getPageContent(state);
                return pageContent ? {
                    ...state,
                    ...pageContentMutator.addComponentContent(pageContent, componentContent)
                } : state;
            },

            shareComponent: ({state}, componentId: Component['id']): AppStoreState => {
                const pageContent = getPageContent(state);
                return pageContent ? {
                    ...state,
                    ...pageContentMutator.shareComponent(pageContent, componentId)
                } : state;
            },

            unshareComponent: ({state}, componentId: Component['id']): AppStoreState => {
                const pageContent = getPageContent(state);
                return pageContent ? {
                    ...state,
                    ...pageContentMutator.unshareComponent(pageContent, componentId)
                } : state;
            },

            attachComponentContent: ({state}, componentId: Component['id']): AppStoreState => {
                const pageContent = getPageContent(state);
                return pageContent ? {
                    ...state,
                    ...pageContentMutator.attachComponentContent(pageContent, componentId)
                } : state;
            },

            detachComponentContent: ({state}, componentId: Component['id']): AppStoreState => {
                const pageContent = getPageContent(state);
                return pageContent ? {
                    ...state,
                    ...pageContentMutator.detachComponentContent(pageContent, componentId)
                } : state;
            }

        }

    });

}