import {PageTypeOptions} from '@webaker/app';
import {UNAUTHORIZED_PAGE_TYPE, UnauthorizedPage} from './unauthorized-page';

export interface UnauthorizedPageOptions extends PageTypeOptions<UnauthorizedPage> {

}

export function createUnauthorizedPageOptions(): UnauthorizedPageOptions {
    return {
        type: UNAUTHORIZED_PAGE_TYPE,
        static: true
    };
}