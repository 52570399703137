import {css} from '@webaker/package-css';
import {CSSThemeColor, CSSThemeProps} from '@webaker/package-css-theme';
import {adjustColor, contrast} from '@webaker/package-utils';
import {ButtonStyle} from './button';

export type ButtonClassName = 'button' | 'icon' | 'label' | 'is-active' | `style-${ButtonStyle}`;

export function ButtonCSS({theme}: CSSThemeProps) {

    return css<ButtonClassName>`

        .button {
            position: relative;
            border-radius: ${theme.roundness.medium};
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: ${theme.spacing.medium};
            padding: ${theme.spacing.medium};
            font-weight: bold;
            height: 2.25em;
            transition: background-color ${theme.duration.short}, filter ${theme.duration.short};

            &:disabled {
                filter: saturate(0.1) contrast(0.2) brightness(1.5);
                cursor: default;
            }
        }

        .icon {
            vertical-align: middle;
            font-size: ${theme.fontSize.large};
        }

        .label {
            white-space: nowrap;
        }

        .style-solid {
            background-color: var(--color);
            color: var(--colorContrast);
            border: none;

            &:hover:not(:disabled):not(:active) {
                filter: brightness(1.1);
            }

            &:active:not(:disabled) {
                filter: brightness(1.2);
            }
        }

        .style-input {
            background-color: ${theme.color.white};
            color: ${theme.color.black};
            border: 1px solid ${theme.color.light};

            &:hover:not(:disabled):not(:active) {
                border-color: ${theme.color.gray};
            }
        }

        .style-text {
            background-color: transparent;
            color: var(--color);
            border: none;

            &:hover:not(:disabled):not(:active, .is-active) {
                background-color: var(--colorA1);
            }

            &:where(:active, .is-active):not(:disabled) {
                background-color: var(--colorA2);
            }
        }

        .style-outline {
            background-color: transparent;
            color: var(--color);
            border: 1px solid var(--color);

            &:hover:not(:disabled):not(:active, .is-active) {
                background-color: var(--colorA1);
            }

            &:where(:active, .is-active):not(:disabled) {
                background-color: var(--colorA2);
            }
        }

    `;
}

export interface ButtonPrivateCSSProps extends CSSThemeProps {
    color: CSSThemeColor;
}

export function ButtonPrivateCSS({theme, color}: ButtonPrivateCSSProps) {

    return css<ButtonClassName>`

        .button {
            --color: ${theme.color[color]};
            --colorContrast: ${contrast(theme.color[color])};
            --colorA1: ${adjustColor(theme.color[color], {alpha: 0.1})};
            --colorA2: ${adjustColor(theme.color[color], {alpha: 0.2})};
        }

    `;
}