import {PageRegistry} from '@webaker/app';
import {Provider} from '@webaker/package-deps';
import {UserRole} from '@webaker/package-user';
import {createFileManagerPageOptions} from '../file-manager-page-options';

export interface ProvidedFileManagerPageClientDeps {

}

export interface RequiredFileManagerPageClientDeps {
    pageRegistry: PageRegistry;
}

export interface FileManagerPageClientDeps extends ProvidedFileManagerPageClientDeps, RequiredFileManagerPageClientDeps {

}

export interface FileManagerPageClientConfig {
    adminRoleId: UserRole['id'];
}

export type FileManagerPageServerProvider = Provider<FileManagerPageClientDeps, FileManagerPageClientConfig>;

export function createFileManagerPageClientProvider(): FileManagerPageServerProvider {

    return {

        registerServices: async ({resolve}, config) => {
            const pageRegistry = resolve('pageRegistry');
            const fileManagerPageOptions = createFileManagerPageOptions({adminRoleId: config.adminRoleId});
            pageRegistry.registerPageType(fileManagerPageOptions);
        }

    };
}