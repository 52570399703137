import {ComponentRegistry} from '@webaker/app';
import {Provider} from '@webaker/package-deps';
import {createSlotComponentOptions} from '../slot-component-options';

export interface ProvidedSlotComponentClientDeps {

}

export interface RequiredSlotComponentClientDeps {
    componentRegistry: ComponentRegistry;
}

export interface SlotComponentClientDeps extends ProvidedSlotComponentClientDeps, RequiredSlotComponentClientDeps {

}

export interface SlotComponentClientConfig {

}

export type SlotComponentServerProvider = Provider<SlotComponentClientDeps, SlotComponentClientConfig>;

export function createSlotComponentClientProvider(): SlotComponentServerProvider {

    return {

        registerServices: async ({resolve}) => {
            const componentRegistry = resolve('componentRegistry');
            const slotComponentOptions = createSlotComponentOptions();
            componentRegistry.registerComponentType(slotComponentOptions);
        }

    };
}