import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type ColorPickerClassName = 'colorPicker' | 'previewButton' | 'openButton';

export interface ColorPickerCSSProps extends CSSThemeProps {

}

export function ColorPickerCSS({theme}: ColorPickerCSSProps) {
    return css<ColorPickerClassName>`

        .colorPicker {
            --color: #fff;
        }

        .previewButton {
            margin: ${theme.spacing.small} ${theme.spacing.small} ${theme.spacing.small} 0;
            aspect-ratio: 1;
            border-radius: ${theme.roundness.small};
            border: 1px solid ${theme.color.light};
            background-image: linear-gradient(var(--color), var(--color)),
            repeating-linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee),
            repeating-linear-gradient(45deg, #eee 25%, #fff 25%, #fff 75%, #eee 75%, #eee);
            background-position: 0 0, 0 0, 8px 8px;
            background-size: 100%, 16px 16px, 16px 16px;
        }

        .openButton {

        }

    `;
}