import {useCallback} from 'react';
import {useDependency} from '@webaker/package-deps';
import {useStore} from '@webaker/package-store';
import {Button, MdIcon, Tooltip, useToast} from '@webaker/package-ui';
import {useSafeHotkey} from '@webaker/package-utils';
import {FileManagerStore} from '../../file-manager-store';

export interface CutFileButtonProps {

}

export interface CutFileButtonDeps {
    fileManagerStore: FileManagerStore;
}

export function CutFileButton({}: CutFileButtonProps) {

    const fileManagerStore = useStore(useDependency<CutFileButtonDeps>()('fileManagerStore'));
    const isAnyNodeSelected = fileManagerStore.getSelectedNodes().length > 0;
    const toast = useToast();

    const cutFiles = useCallback(() => {
        if (isAnyNodeSelected) {
            const selectedNodesText = fileManagerStore.getSelectedNodesText();
            fileManagerStore.cutNodes();
            toast(`${selectedNodesText} has been cut`);
        }
    }, [isAnyNodeSelected]);

    useSafeHotkey('Control+X', cutFiles);

    return (
        <Tooltip title="Cut"
                 color={isAnyNodeSelected ? 'dark' : 'gray'}>
            <Button color="info"
                    style="text"
                    icon={<MdIcon name="cut"/>}
                    onClick={cutFiles}
                    disabled={!isAnyNodeSelected}/>
        </Tooltip>
    );

}