import {useCallback, useEffect} from 'react';
import {useDependency} from '@webaker/package-deps';
import {Directory, File, FileApi} from '@webaker/package-file';
import {useStore} from '@webaker/package-store';
import {Button, MdIcon, Modal, ModalButtons, ModalInfo} from '@webaker/package-ui';
import {FileManagerStore} from '../../file-manager-store';

export interface DeleteFilesModalProps {
    onClose: () => void;
}

export interface DeleteFilesModalDeps {
    fileManagerStore: FileManagerStore;
    fileApi: FileApi;
}

export function DeleteFilesModal({onClose}: DeleteFilesModalProps) {

    const fileApi = useDependency<DeleteFilesModalDeps>()('fileApi');
    const fileManagerStore = useStore(useDependency<DeleteFilesModalDeps>()('fileManagerStore'));
    const selectedNodesText = fileManagerStore.getSelectedNodesText();

    const deleteFiles = useCallback(async () => {
        fileManagerStore.startLoading();
        const selectedFiles = fileManagerStore.getSelectedFiles();
        const selectedDirectories = fileManagerStore.getSelectedDirectories();
        await Promise.all([
            ...selectedFiles.map((file: File): Promise<void> => {
                return fileApi.deleteFile(file.id);
            }),
            ...selectedDirectories.map((directory: Directory): Promise<void> => {
                return fileApi.deleteDirectory(directory.id);
            })
        ]);
        selectedFiles.forEach((file: File): void => {
            fileManagerStore.deleteFile(file.id);
        });
        selectedDirectories.forEach((directory: Directory): void => {
            fileManagerStore.deleteDirectory(directory.id);
        });
        fileManagerStore.stopLoading();
        fileManagerStore.deselectAllNodes();
        onClose();
    }, [onClose]);

    useEffect(() => {
        if (!selectedNodesText) {
            onClose();
        }
    }, [selectedNodesText]);

    return (
        <Modal title="Delete files"
               onEnter={deleteFiles}
               onEscape={onClose}>
            <ModalInfo center={true}>
                Are you sure you want to delete {selectedNodesText}?
            </ModalInfo>
            <ModalButtons>
                <Button color="dark"
                        style="text"
                        onClick={onClose}>
                    Cancel
                </Button>
                <Button color="danger"
                        style="solid"
                        icon={<MdIcon name="delete"/>}
                        onClick={deleteFiles}>
                    Delete
                </Button>
            </ModalButtons>
        </Modal>
    );

}