import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {ReactNode} from 'react';
import {WrapperCSS} from './wrapper-css';

export interface WrapperProps {
    className?: string;
    children?: ReactNode;
}

export function Wrapper({className, children}: WrapperProps) {

    const css = useThemedCSS(WrapperCSS);

    return (
        <div className={mergeClassNames(css['wrapper'], className)}>
            {children}
        </div>
    );

}