import {File, FileStore} from '@webaker/package-file';
import {Theme} from './theme';

export interface ThemeRenderer {
    renderTheme: (theme: Theme) => string;
    renderThemes: (themes: Theme[]) => string;
}

export interface ThemeRendererDeps {
    fileStore: FileStore;
}

export function createThemeRenderer({fileStore}: ThemeRendererDeps): ThemeRenderer {

    const renderTheme = (theme: Theme): string => {
        const selector = `.${theme.id}`;
        const files = fileStore.getFilesByIds(theme.filesIds);
        const propertiesString = Object.entries(theme.properties).map(([name, value]) => {
            const processedValue = processValue(value, files);
            return `${name}:${processedValue};`;
        }).join('');
        return `${selector}{${propertiesString}}`;
    };

    const renderThemes = (themes: Theme[]): string => {
        const sharedCSS = themes.filter((theme: Theme): boolean => {
            return !!theme.shared;
        }).map(renderTheme).join('');
        const personalCSS = themes.filter((theme: Theme): boolean => {
            return !theme.shared;
        }).map(renderTheme).join('');
        return `${sharedCSS}${personalCSS}`;
    };

    const processValue = (value: string, files: File[]): string => {
        files.forEach((file: File): void => {
            value = value.replace(file.id, file.url ?? '');
        });
        return value;
    };

    return {
        renderTheme,
        renderThemes
    };

}