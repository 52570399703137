import {FunctionComponent} from 'react';

export interface ReactWrapper {
    wrapComponent: <P = unknown, O extends string = typeof ORIGINAL_PROP>(
        Component: FunctionComponent<P>,
        wrappers?: WrapperComponent<P, O>[],
        originalProp?: O
    ) => FunctionComponent<P>;
}

export type WrapperComponent<P = unknown, O extends string = string> = FunctionComponent<WrapperComponentProps<P, O>>;

export type WrapperComponentProps<P = unknown, O extends string = string> = P & {
    [K in O]: FunctionComponent<P>;
};

export const ORIGINAL_PROP = 'Original';

export function createReactWrapper(): ReactWrapper {

    const cacheMap = new Map<FunctionComponent<any>[], FunctionComponent<any>>();

    const wrapComponent = <P = unknown, O extends string = typeof ORIGINAL_PROP>(
        Component: FunctionComponent<P>,
        wrappers: WrapperComponent<P, O>[],
        originalProp: O = ORIGINAL_PROP as O
    ): FunctionComponent<P> => {
        const components = [...wrappers, Component] as FunctionComponent<P>[];
        const CachedComponent = getCachedComponent(components);
        if (CachedComponent) {
            return CachedComponent;
        }
        const WrapperComponent = wrappers.reduce((CurrentComponent: FunctionComponent<P>, WrapperComponent: WrapperComponent<P, O>): FunctionComponent<P> => {
            return ((props: WrapperComponentProps<P, O>) => {
                const extendedProps = {...props, [originalProp]: CurrentComponent};
                return <WrapperComponent {...extendedProps}/>;
            }) as FunctionComponent<P>;
        }, Component);
        setCachedComponent(components, WrapperComponent);
        return WrapperComponent;
    };

    const getCachedComponent = <P = unknown>(components: FunctionComponent<P>[]): FunctionComponent<P> | null => {
        return Array.from(cacheMap.entries()).find(([key]) => {
            return areArraysEqual(key, components);
        })?.[1] ?? null;
    };

    const setCachedComponent = <P = unknown>(components: FunctionComponent<P>[], Component: FunctionComponent<P>): void => {
        cacheMap.set(components, Component);
    };

    const areArraysEqual = (arrayA: unknown[], arrayB: unknown[]): boolean => {
        return arrayA.length === arrayB.length &&
            arrayA.every((_, index) => arrayA[index] === arrayB[index]);
    };

    return {
        wrapComponent
    };

}