import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type CornerModelClassName =
    'cornerBox'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'center'
    | 'topRow'
    | 'middleRow'
    | 'bottomRow'
    | 'label'
    | 'is-clickable';

export interface CornerModelCSSProps extends CSSThemeProps {

}

export function CornerBoxCSS({theme}: CornerModelCSSProps) {
    return css<CornerModelClassName>`

        .cornerBox {
            position: relative;
            width: 100%;
            margin: ${theme.spacing.medium} 0 ${theme.spacing.large} 0;

            &:before {
                content: '';
                display: block;
                position: absolute;
                left: ${theme.spacing.large};
                top: ${theme.spacing.large};
                right: ${theme.spacing.large};
                bottom: ${theme.spacing.large};
                border-radius: ${theme.roundness.medium};
                border: 1px solid ${theme.color.light};
            }
        }

        .topRow, .middleRow, .bottomRow {
            display: flex;
        }

        .topRow, .bottomRow {
            justify-content: space-between;
        }

        .middleRow {
            justify-content: center;
            margin: ${theme.spacing.large} 0;
        }

        .label {
            font-size: ${theme.fontSize.small};
            color: ${theme.color.gray};
            position: absolute;
            bottom: calc(100% + 2px);
            text-align: center;
            width: 100%;

            &.is-clickable {
                cursor: pointer;

                &:hover {
                    color: ${theme.color.black};
                }
            }
        }

        .topLeft, .topRight, .bottomLeft, .bottomRight {
            width: 40%;
            display: flex;
            flex-direction: column;
            gap: ${theme.spacing.small};
            position: relative;
            min-height: 2.25em;
        }

        .center {
            display: flex;
            align-items: center;
        }

        .bottom {
            .label {
                bottom: unset;
                top: calc(100% + 2px);
            }
        }

    `;
}