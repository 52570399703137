import {useCallback} from 'react';
import {useDependency} from '@webaker/package-deps';
import {DirectoryFactory} from '@webaker/package-file';
import {useStore} from '@webaker/package-store';
import {Button, MdIcon, Tooltip} from '@webaker/package-ui';
import {FileManagerStore} from '../../file-manager-store';

export interface CreateDirectoryButtonProps {

}

export interface CreateDirectoryButtonDeps {
    fileManagerStore: FileManagerStore;
    directoryFactory: DirectoryFactory;
}

export function CreateDirectoryButton({}: CreateDirectoryButtonProps) {

    const fileManagerStore = useStore(useDependency<CreateDirectoryButtonDeps>()('fileManagerStore'));
    const directoryFactory = useDependency<CreateDirectoryButtonDeps>()('directoryFactory');

    const createNewDirectory = useCallback(() => {
        const workingDirectory = fileManagerStore.getWorkingDirectory();
        const newDirectory = directoryFactory.createDirectory({
            parentDirectoryId: workingDirectory?.id
        });
        fileManagerStore.addDirectory(newDirectory);
        fileManagerStore.selectNode(newDirectory.id);
        fileManagerStore.editNode(newDirectory.id);
    }, []);

    return (
        <Tooltip title={'New directory'}>
            <Button color="success"
                    style="text"
                    icon={<MdIcon name="create_new_folder"/>}
                    onClick={createNewDirectory}/>
        </Tooltip>
    );

}