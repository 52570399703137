import {css} from '@webaker/package-css';
import {CSSThemeProps, CSSThemeSize} from '@webaker/package-css-theme';
import {RowAlign} from './row';

export type RowClassName = 'row';

export function RowCSS({}: CSSThemeProps) {
    return css<RowClassName>`

        .row {
            display: grid;
            grid-auto-columns: 1fr;
            grid-auto-flow: column;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

    `;
}

export interface RowPrivateCSSProps extends CSSThemeProps {
    columns: string[];
    align: RowAlign;
    margin: CSSThemeSize;
    gap: CSSThemeSize;
}

export function RowPrivateCSS({theme, columns, align, margin, gap}: RowPrivateCSSProps) {
    return css<RowClassName>`
        
        .row {
            align-items: ${align};
            grid-gap: ${theme.spacing[gap!]};
            margin: ${theme.spacing[margin!]} 0;
            grid-template-columns: ${columns.join(' ')};
        }

    `;
}