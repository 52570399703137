import {CSSThemeColor, useThemedCSSBundle} from '@webaker/package-css-theme';
import {mergeClassNames, useBooleanState} from '@webaker/package-utils';
import {Fragment, ReactNode, useEffect} from 'react';
import {Portal} from './portal';
import {TooltipCSS, TooltipPrivateCSS} from './tooltip-css';

export interface TooltipProps {
    title: string | undefined | null;
    color?: CSSThemeColor;
    visible?: boolean;
    children?: ReactNode;
    className?: string;
    wrapperClassName?: string;
}

export function Tooltip({title, color, visible, children, className, wrapperClassName}: TooltipProps) {

    const css = useThemedCSSBundle([TooltipCSS, TooltipPrivateCSS], {
        color: color ?? 'dark'
    });

    const [isOpen, open, close] = useBooleanState(false);

    useEffect(() => {
        if (visible === true) {
            open();
        } else if (visible === false) {
            close();
        }
    }, [visible]);

    if (!title) {
        return (
            <Fragment>
                {children}
            </Fragment>
        );
    }

    return (
        <div className={mergeClassNames(css['wrapper'], wrapperClassName)}
             onMouseOver={visible === undefined ? open : undefined}
             onMouseOut={visible === undefined ? close : undefined}>
            {children}
            {isOpen && (
                <Portal>
                    <div className={mergeClassNames(css['tooltip'], className)}>
                        {title}
                    </div>
                </Portal>
            )}
        </div>
    );

}