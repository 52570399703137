import {ComponentFactory, ComponentRegistry, ComponentValidator} from '@webaker/app';
import {ApiClientFactory} from '@webaker/package-api/client';
import {Provider} from '@webaker/package-deps';
import {EventBus} from '@webaker/package-event-bus';
import {FileApi, FileStore} from '@webaker/package-file';
import {ThemeStore} from '@webaker/package-theme';
import {ThemeApi} from '../theme-api';
import {createThemeComponentFactoryFunction} from '../theme-component-factory-function';
import {createThemeComponentOptions} from '../theme-component-options';
import {createThemeComponentValidationRules} from '../theme-component-validation-rule';
import {createThemeEventSubscriber, ThemeEventSubscriber} from '../theme-event-subscriber';
import {createThemeClientApi} from './theme-client-api';


export interface ProvidedThemeComponentClientDeps {
    themeApi: ThemeApi;
    themeEventSubscriber: ThemeEventSubscriber;
}

export interface RequiredThemeComponentClientDeps {
    apiClientFactory: ApiClientFactory;
    componentFactory: ComponentFactory;
    componentRegistry: ComponentRegistry;
    componentValidator: ComponentValidator;
    eventBus: EventBus;
    fileApi: FileApi;
    fileStore: FileStore;
    themeStore: ThemeStore;
}

export interface ThemeComponentClientDeps extends ProvidedThemeComponentClientDeps, RequiredThemeComponentClientDeps {

}

export interface ThemeComponentClientConfig {

}

export type ThemeComponentClientProvider = Provider<ThemeComponentClientDeps, ThemeComponentClientConfig>;

export function createThemeComponentClientProvider(): ThemeComponentClientProvider {
    return {

        registerDependencies: async ({register}) => {
            register('themeApi', ({resolve}) => {
                return createThemeClientApi({
                    apiClientFactory: resolve('apiClientFactory')
                });
            });
            register('themeEventSubscriber', ({resolve}) => {
                return createThemeEventSubscriber({
                    eventBus: resolve('eventBus'),
                    fileApi: resolve('fileApi'),
                    fileStore: resolve('fileStore'),
                    themeApi: resolve('themeApi'),
                    themeStore: resolve('themeStore')
                });
            });
        },

        registerServices: async ({resolve}) => {
            const componentRegistry = resolve('componentRegistry');
            const themedComponentOptions = createThemeComponentOptions();
            componentRegistry.registerComponentExtension(themedComponentOptions);

            const componentFactory = resolve('componentFactory');
            const themedComponentFactoryFunction = createThemeComponentFactoryFunction();
            componentFactory.registerFactory(themedComponentFactoryFunction);

            const componentValidator = resolve('componentValidator');
            const themedComponentValidationRules = createThemeComponentValidationRules();
            componentValidator.registerRules(themedComponentValidationRules);
        },

        runServices: async ({resolve}) => {
            const themeEventSubscriber = resolve('themeEventSubscriber');
            themeEventSubscriber.subscribeEvents();
        }

    };
}