import {IdGenerator, omitUndefined} from '@webaker/package-utils';
import {Theme} from './theme';

export interface ThemeFactory {
    createTheme: (data?: Partial<Theme>) => Theme;
}

export interface ThemeFactoryDeps {
    idGenerator: IdGenerator;
}

const THEME_ID_LENGTH = 5;
const NUMBER_TO_LETTER_CHAR_CODE_DIFF = 'a'.charCodeAt(0) - '0'.charCodeAt(0);

export function createThemeFactory({idGenerator}: ThemeFactoryDeps): ThemeFactory {

    const createTheme = (data: Partial<Theme> = {}): Theme => {
        return omitUndefined({
            id: data.id ?? generateId(),
            filesIds: data.filesIds ?? [],
            name: data.name ?? '',
            properties: data.properties ?? {},
            shared: data.shared ?? undefined
        });
    };

    const generateId = (): string => {
        let id = idGenerator.generateId(THEME_ID_LENGTH);
        return !isNaN(parseInt(id.charAt(0)))
            ? String.fromCharCode(id.charCodeAt(0) + NUMBER_TO_LETTER_CHAR_CODE_DIFF) + id.slice(1)
            : id;
    };

    return {
        createTheme
    };

}