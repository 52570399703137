import {PageRegistry} from '@webaker/app';
import {Provider} from '@webaker/package-deps';
import {createHomePageOptions} from '../home-page-options';

export interface ProvidedHomePageClientDeps {

}

export interface RequiredHomePageClientDeps {
    pageRegistry: PageRegistry;
}

export interface HomePageClientDeps extends ProvidedHomePageClientDeps, RequiredHomePageClientDeps {

}

export interface HomePageClientConfig {

}

export type HomePageClientProvider = Provider<HomePageClientDeps, HomePageClientConfig>;

export function createHomePageClientProvider(): HomePageClientProvider {

    return {

        registerServices: async ({resolve}) => {
            const pageRegistry = resolve('pageRegistry');
            const homePageOptions = createHomePageOptions();
            pageRegistry.registerPageType(homePageOptions);
        }

    };
}