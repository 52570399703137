import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames, useBooleanState} from '@webaker/package-utils';
import {MutableRefObject, useCallback, useMemo} from 'react';
import {Autocomplete, AutocompleteOption, AutocompleteSelectEvent} from './autocomplete';
import {Input, InputChangeEvent} from './input';
import {TextFieldCSS} from './text-field-css';

export interface TextFieldProps {
    value: string | null;
    onChange?: (event: TextFieldChangeEvent) => void;
    placeholder?: string | null;
    disabled?: boolean;
    validate?: (value: string | null) => boolean;
    autocomplete?: string[];
    autocompleteMinLength?: number;
    className?: string;
    inputRef?: MutableRefObject<HTMLInputElement | null>;
}

export interface TextFieldChangeEvent {
    value: string | null;
}

export function TextField({
    value,
    onChange,
    placeholder,
    disabled,
    validate,
    autocomplete,
    autocompleteMinLength,
    className,
    inputRef
}: TextFieldProps) {

    const css = useThemedCSS(TextFieldCSS, {});
    const [isFocused, enableFocus, disableFocus] = useBooleanState(false);

    const handleChange = useCallback((event: InputChangeEvent) => {
        if (!validate || validate(event.value)) {
            onChange?.({value: event.value});
        }
    }, [onChange, validate]);

    const invalid = useMemo(() => {
        return validate && !validate(value);
    }, [validate, value]);

    const autocompleteOptions = useMemo<AutocompleteOption<string>[]>(() => {
        return autocomplete?.filter((option: string): boolean => {
            return option.startsWith(value ?? '');
        }).map((option: string): AutocompleteOption => {
            return {value: option, label: option};
        }) ?? [];
    }, [value, ...(autocomplete ?? [])]);

    const handleAutocompleteSelect = useCallback((event: AutocompleteSelectEvent) => {
        onChange?.({value: event.value});
    }, [onChange]);

    const shouldDisplayAutocomplete = autocomplete && isFocused && (value ?? '').length >= (autocompleteMinLength ?? 0);

    const autocompleteElement = shouldDisplayAutocomplete ? (
        <Autocomplete options={autocompleteOptions}
                      onSelect={handleAutocompleteSelect}/>
    ) : null;

    return (
        <Input className={mergeClassNames(css['textField'], className)}
               value={value}
               onChange={handleChange}
               onFocus={enableFocus}
               onBlur={disableFocus}
               placeholder={placeholder}
               outside={autocompleteElement}
               disabled={disabled}
               invalid={invalid}
               inputRef={inputRef}/>
    );

}