import {Store, StoreFactory} from '@webaker/package-store';
import {User} from './user';

export const USER_STORE_NAME = 'user';

export interface UserStoreState {
    loggedUser: User | null;
}

export interface UserStore extends Store<UserStoreState> {
    name: typeof USER_STORE_NAME;
    getLoggedUser: () => User | null;
    setLoggedUser: (user: User | null) => void;
}

export interface UserStoreDeps {
    storeFactory: StoreFactory;
}

export function createUserStore({storeFactory}: UserStoreDeps): UserStore {

    return storeFactory.createStore<UserStore>({

        name: USER_STORE_NAME,

        state: {
            loggedUser: null
        },

        getters: {

            getLoggedUser: ({state}): User | null => {
                return state.loggedUser;
            }

        },

        setters: {

            setLoggedUser: ({state}, user: User | null): UserStoreState => {
                return {
                    ...state,
                    loggedUser: user
                };
            }

        }

    });

}