import {AppExtension} from '@webaker/app';
import {createContainer, DependencyContainer, runProvider} from '@webaker/package-deps';
import {ExtensionManager} from '@webaker/package-extension';
import {createExtensionClientManager} from '@webaker/package-extension/client';
import {BootClientConfig, getClientConfig} from './boot-client-config';
import {BootClientDeps, BootClientProvider, createBootClientProvider} from './boot-client-provider';

type ClientContainer = DependencyContainer<BootClientDeps>;

async function startApp(): Promise<void> {
    const config = getClientConfig();
    const extensionManager = await createExtensionClientManager<AppExtension>();
    extensionManager.registerExtensions(config.extensions);
    const extensions = extensionManager.getEnabledExtensions();
    const clientContainer = await createClientContainer(extensionManager);
    const clientProvider = await createBootClientProvider(extensions);
    await runProvider(clientProvider, clientContainer, config);
    await bindErrorHandler(clientProvider, clientContainer, config);
}

async function createClientContainer(extensionManager: ExtensionManager): Promise<ClientContainer> {
    const clientContainer: ClientContainer = createContainer();
    clientContainer.register('container', () => clientContainer);
    clientContainer.register('extensionManager', () => extensionManager);
    return clientContainer;
}

async function bindErrorHandler(clientProvider: BootClientProvider, clientContainer: ClientContainer, config: BootClientConfig): Promise<void> {
    window.addEventListener('error', async (event: ErrorEvent) => {
        event.preventDefault();
        const error = event.error ?? new Error(event.message ?? 'Unknown error');
        const isErrorHandled: boolean = await clientProvider.handleError?.(error, clientContainer, config) ?? false;
        if (!isErrorHandled) {
            console.error(error);
        }
    });
}

(async () => await startApp())();