import {useCallback} from 'react';
import {useThemedCSS} from '@webaker/package-css-theme';
import {useDependency} from '@webaker/package-deps';
import {FileApi} from '@webaker/package-file';
import {useStore} from '@webaker/package-store';
import {MdIcon} from '@webaker/package-ui';
import {FileManagerStore} from '../../file-manager-store';
import {NodeListHeaderCSS} from './node-list-header-css';

export interface FileListHeaderProps {

}

export interface FileListHeaderDeps {
    fileApi: FileApi;
    fileManagerStore: FileManagerStore;
}

export function NodeListHeader({}: FileListHeaderProps) {

    const css = useThemedCSS(NodeListHeaderCSS);
    const fileManagerStore = useStore(useDependency<FileListHeaderDeps>()('fileManagerStore'));
    const fileApi = useDependency<FileListHeaderDeps>()('fileApi');
    const workingDirectory = fileManagerStore.getWorkingDirectory();

    const openParentDirectory = useCallback(async () => {
        if (workingDirectory) {
            fileManagerStore.startLoading();
            const parentDirectoryTree = await fileApi.getDirectoryTree(workingDirectory.parentDirectoryId ?? null);
            fileManagerStore.openDirectory(parentDirectoryTree);
            fileManagerStore.stopLoading();
        }
    }, [workingDirectory]);

    return (
        <div className={css['nodeListHeader']}>
            <div className={css['iconColumn']}>
                {workingDirectory && (
                    <span className={css['goUpButton']}
                          onClick={openParentDirectory}>
                        <MdIcon name="arrow_top_left"/>
                    </span>
                )}
            </div>
            <div className={css['nameColumn']}>
                Name
            </div>
            <div className={css['sizeColumn']}>
                Size
            </div>
            <div className={css['accessColumn']}>
                Access
            </div>
        </div>
    );

}