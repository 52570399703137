import {Fragment, useCallback} from 'react';
import {useDependency} from '@webaker/package-deps';
import {File, FileApi} from '@webaker/package-file';
import {useStore} from '@webaker/package-store';
import {Button, MdIcon, Tooltip} from '@webaker/package-ui';
import {useBooleanState} from '@webaker/package-utils';
import {FileManagerStore} from '../../file-manager-store';
import {FileUsageErrorModal} from '../modals/file-usage-error-modal';

export interface AccessFileButtonProps {

}

export interface AccessFileButtonDeps {
    fileApi: FileApi;
    fileManagerStore: FileManagerStore;
}

export function AccessFileButton({}: AccessFileButtonProps) {

    const fileManagerStore = useStore(useDependency<AccessFileButtonDeps>()('fileManagerStore'));
    const fileApi = useDependency<AccessFileButtonDeps>()('fileApi');
    const [isFileUsageErrorModalOpen, openFileUsageErrorModal, closeFileUsageErrorModal] = useBooleanState(false);
    const selectedFiles = fileManagerStore.getSelectedFiles();
    const isAnyFileSelected = selectedFiles.length > 0;

    const toggleAccess = useCallback(async () => {
        const selectedFilesIds = fileManagerStore.getSelectedFilesIds();
        if (await fileApi.isAnyFileInUse(selectedFilesIds)) {
            openFileUsageErrorModal();
            return;
        }

        const newPublic = !selectedFiles[0].public;
        await Promise.all(
            selectedFiles.map((file: File) => {
                const newFile = {...file, public: newPublic};
                fileManagerStore.updateFile(newFile);
                return fileApi.saveFile(newFile);
            })
        );
    }, [selectedFiles]);

    return (
        <Fragment>
            <Tooltip title="Change access"
                     color={isAnyFileSelected ? 'dark' : 'gray'}>
                <Button color="info"
                        style="text"
                        icon={<MdIcon name="public"/>}
                        onClick={toggleAccess}
                        disabled={!isAnyFileSelected}/>
            </Tooltip>
            {isFileUsageErrorModalOpen && (
                <FileUsageErrorModal info="One or more selected files are in use, you cannot make them private."
                                     onClose={closeFileUsageErrorModal}/>
            )}
        </Fragment>
    );

}