import {css} from '@webaker/package-css';
import {CSSThemeColor, CSSThemeProps, CSSThemeSize} from '@webaker/package-css-theme';
import {contrast} from '@webaker/package-utils';

export type AlertClassName = 'alert' | 'icon' | 'message';

export function AlertCSS({theme}: CSSThemeProps) {
    return css<AlertClassName>`

        .alert {
            position: relative;
            padding: ${theme.spacing.medium};
            border-radius: ${theme.roundness.medium};
            display: flex;
            align-items: center;
            gap: ${theme.spacing.medium};

            a {
                font-weight: bold;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .icon {
            font-size: ${theme.fontSize.large};
            margin: 0 ${theme.spacing.small};
            display: flex;
            flex-direction: column;
            gap: ${theme.spacing.medium};
        }

        .message {
            flex: 1;
        }

    `;
}

export interface AlertPrivateCSSProps extends CSSThemeProps {
    color: CSSThemeColor;
    margin: CSSThemeSize;
}

export function AlertPrivateCSS({theme, color, margin}: AlertPrivateCSSProps) {
    return css<AlertClassName>`

        .alert {
            background-color: ${theme.color[color]};
            color: ${contrast(theme.color[color])};
            margin: ${theme.spacing[margin]} 0;

            * {
                color: ${contrast(theme.color[color])};
            }
        }

    `;
}