import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type TreeNodeClassName =
    'treeNode'
    | 'item'
    | 'arrow'
    | 'label'
    | 'sublabel'
    | 'buttons'
    | 'children'
    | 'is-active'
    | 'is-empty'
    | 'is-expandable'
    | 'is-open'
    | 'is-clickable';

export interface TreeNodeCSSProps extends CSSThemeProps {

}

export function TreeNodeCSS({theme}: TreeNodeCSSProps) {
    return css<TreeNodeClassName>`

        .treeNode {
            position: relative;
            color: ${theme.color.black};
        }

        .item {
            display: flex;
            flex-direction: row;
            margin: ${theme.spacing.small} 0;
            position: relative;
            cursor: default;
        }

        .arrow {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 1em;;
        }

        .is-expandable .arrow {
            cursor: pointer;
        }

        .label {
            padding: ${theme.spacing.medium} ${theme.spacing.large} ${theme.spacing.medium} ${theme.spacing.small};
            width: 100%;
            white-space: nowrap;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: ${theme.spacing.small};
            flex: 1;

            > * {
                min-width: 1em;
            }
        }

        .sublabel {
            font-size: ${theme.fontSize.small};
            color: ${theme.color.light};
            display: flex;
            flex-direction: row;
            gap: ${theme.spacing.small};
            width: 100%;
        }

        .buttons {
            display: flex;
        }

        .children {
            display: none;
            position: relative;
            margin-left: 20px;;
        }

        .is-expandable > .children:before {
            content: '';
            display: block;
            position: absolute;
            left: -12px;
            width: 1px;
            height: 100%;
            background: ${theme.color.veryLight};
        }

        .is-active > .item {
            color: ${theme.color.marked};
            font-weight: bold;

            .sublabel {
                color: ${theme.color.gray};
            }
        }

        .is-empty .arrow {
            cursor: default;
        }

        .is-empty .children {
            display: none;
        }

        .is-open > .children {
            display: block;
        }

        .is-clickable > .item {
            cursor: pointer;
        }

    `;
}