import {ChangeEvent, KeyboardEvent, MouseEvent, MutableRefObject, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {useThemedCSS} from '@webaker/package-css-theme';
import {useDependency} from '@webaker/package-deps';
import {FileNameParser, FS_NODE_NAME_REGEXP, FSNode, MAX_FS_NODE_NAME_LENGTH, MIN_FS_NODE_NAME_LENGTH} from '@webaker/package-file';
import {useStore} from '@webaker/package-store';
import {mergeClassNames} from '@webaker/package-utils';
import {FileManagerStore} from '../../file-manager-store';
import {NodeNameFieldCSS} from './node-name-field-css';

export interface NodeNameFieldProps {
    node: FSNode;
    onChange: (node: FSNode) => void;
    onCancel?: (node: FSNode) => void;
}

export interface NodeNameFieldDeps {
    fileManagerStore: FileManagerStore;
    fileNameParser: FileNameParser;
}

export function NodeNameField({node, onChange, onCancel}: NodeNameFieldProps) {

    const css = useThemedCSS(NodeNameFieldCSS, {});
    const fileManagerStore = useStore(useDependency<NodeNameFieldDeps>()('fileManagerStore'));
    const fileNameParser = useDependency<NodeNameFieldDeps>()('fileNameParser');
    const isSelected = fileManagerStore.isNodeSelected(node.id);
    const isEdited = fileManagerStore.isNodeEdited(node.id);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const nodes = fileManagerStore.getSortedNodes();
    const [name, setName] = useState(node.name);

    const isNameValid = useMemo(() => {
        const nameIsTooShort = name.length < MIN_FS_NODE_NAME_LENGTH;
        const nameIsTooLong = name.length > MAX_FS_NODE_NAME_LENGTH;
        const nameContainsValidCharacters = name.trim().match(FS_NODE_NAME_REGEXP) !== null;
        const anotherNodeWithSameNameExists = nodes.some((anotherNode: FSNode): boolean => {
            return anotherNode.id !== node.id && anotherNode.name === name.trim();
        });
        return !nameIsTooShort && !nameIsTooLong && nameContainsValidCharacters && !anotherNodeWithSameNameExists;
    }, [name]);

    const cancelName = useCallback(() => {
        setName(node.name);
        fileManagerStore.stopEditingNode();
        onCancel?.(node);
    }, [node]);

    const applyName = useCallback(() => {
        if (!isNameValid) {
            cancelName();
            return;
        }
        const newNode = {...node, name: name.trim()};
        fileManagerStore.stopEditingNode();
        onChange(newNode);
    }, [node, name, isNameValid, cancelName]);

    const handleFocus = useCallback(() => {
        fileManagerStore.editNode(node.id);
    }, [node.id]);

    const handleBlur = useCallback(() => {
        applyName();
    }, [applyName]);

    const handleClick = useCallback(() => {
        if (isSelected) {
            fileManagerStore.editNode(node.id);
        }
    }, [isSelected, node.id]);

    const handleMouseDown = useCallback((event: MouseEvent) => {
        event.preventDefault();
    }, []);

    const handleChange = useCallback((event: ChangeEvent) => {
        const input = event.target as HTMLInputElement;
        setName(input.value);
    }, []);

    const handleKeyDown = useCallback(async (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            applyName();
        }
        if (event.key === 'Escape') {
            cancelName();
        }
    }, [applyName, cancelName]);

    useEffect(() => {
        setName(node.name);
    }, [node.name]);

    useEffect(() => {
        if (isEdited && inputRef.current) {
            const fileBaseName = fileNameParser.getFileBaseName(name);
            inputRef.current.focus();
            inputRef.current.setSelectionRange(0, fileBaseName.length);
            inputRef.current.scrollIntoView({behavior: 'smooth'});
        }
    }, [isEdited]);

    useDynamicWidth(inputRef, [name]);

    return (
        <input ref={inputRef}
               type="text"
               value={name}
               className={mergeClassNames(css['nodeNameField'], !isNameValid && css['is-invalid'])}
               onFocus={handleFocus}
               onBlur={handleBlur}
               onClick={handleClick}
               onMouseDown={handleMouseDown}
               onChange={handleChange}
               onKeyDown={handleKeyDown}
               readOnly={!isEdited}
               spellCheck={false}/>
    );

}

function useDynamicWidth(ref: MutableRefObject<HTMLElement | null>, deps?: unknown[]) {
    if (typeof window !== 'undefined') {
        useLayoutEffect(() => {
            if (ref.current) {
                ref.current.style.width = '0';
                ref.current.style.width = ref.current?.scrollWidth + 'px';
            }
        }, deps);
    }
}