import {getAppMode, PRODUCTION} from '../utils/app-mode';

export interface UserConfig {
    adminUserName: string;
    adminUserEmail: string;
    adminRoleId: string;
    adminRoleName: string;
    passwordMinLength: number;
    passwordStrictPolicy: boolean;
}

export function getUserConfig(): UserConfig {

    const appMode = getAppMode();
    const hostname = new URL(process.env.APP_URL ?? `http://localhost/`).hostname;

    const adminUserName = 'Admin';
    const adminUserEmail = `admin@${hostname}`;
    const adminRoleId = 'admin';
    const adminRoleName = 'Administrator';
    const passwordStrictPolicy = appMode === PRODUCTION;
    const passwordMinLength = appMode === PRODUCTION ? 8 : 6;

    return {
        adminUserName,
        adminUserEmail,
        adminRoleId,
        adminRoleName,
        passwordMinLength,
        passwordStrictPolicy
    };

}