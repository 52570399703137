import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {KeyboardEvent, ReactElement, ReactNode, useCallback, useContext} from 'react';
import {TabCSS} from './tab-css';
import {TabGroupContext} from './tab-group';
import {Tooltip} from './tooltip';

export interface TabProps {
    active?: boolean;
    onClick?: () => void;
    icon?: ReactElement;
    title?: string;
    children?: ReactNode;
    className?: string;
}

export function Tab({active, onClick, icon, title, children, className}: TabProps) {

    const css = useThemedCSS(TabCSS, {});
    const {direction} = useContext(TabGroupContext);

    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        if (event.code === 'Enter' || event.code === 'Space') {
            onClick?.();
        }
    }, [onClick]);

    return (
        <Tooltip title={title}
                 wrapperClassName={css['tooltipWrapper']}>
            <div onClick={onClick}
                 onKeyDown={handleKeyDown}
                 tabIndex={0}
                 className={mergeClassNames(
                     css['tab'],
                     css[`direction-${direction}`],
                     active && css['is-active'],
                     className
                 )}>
                {icon && (
                    <span className={css['icon']}>
                    {icon}
                </span>
                )}
                {children && (
                    <span className={css['label']}>
                    {children}
                </span>
                )}
            </div>
        </Tooltip>
    );

}