import {css} from '@webaker/package-css';
import {CSSThemeProps, CSSThemeSize} from '@webaker/package-css-theme';

export type LabelClassName = 'label' | 'is-active' | 'is-disabled';

export function LabelCSS({theme}: CSSThemeProps) {
    return css<LabelClassName>`

        .label {
            color: ${theme.color.dark};
            font-weight: bold;
            min-height: 2.25em;
            line-height: 2.25em;
            display: flex;
            gap: ${theme.spacing.medium};

            &.is-disabled {
                color: ${theme.color.light};
            }
        }

        .is-active:after {
            content: '';
            display: inline-block;
            position: relative;
            left: -5px;
            top: 10px;
            width: 6px;
            height: 6px;
            border-radius: 100%;
            background-color: ${theme.color.info};
        }

    `;
}

export interface LabelPrivateCSSProps extends CSSThemeProps {
    margin: CSSThemeSize;
}

export function LabelPrivateCSS({theme, margin}: LabelPrivateCSSProps) {
    return css<LabelClassName>`

        .label {
            margin: ${theme.spacing[margin]} 0;
        }

    `;
}