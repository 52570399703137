import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type SwitchClassName = 'switch' | 'is-enabled' | 'mode-default' | 'mode-direction';

export function SwitchCSS({theme}: CSSThemeProps) {

    const padding = '2px';

    return css<SwitchClassName>`

        .switch {
            aspect-ratio: 2;
            position: relative;
            padding: ${padding};
            cursor: pointer;
            background-color: ${theme.color.white};
            border: 1px solid ${theme.color.light};
            height: 1.5em;
            border-radius: 0.75em;
            margin: 0.375em 0;
            display: flex;

            &:hover:not(:disabled) {
                border-color: ${theme.color.gray};
            }

            &:disabled {
                background-color: ${theme.color.veryLight};
                cursor: default;
            }

            &:before {
                content: '';
                display: inline-block;
                height: 100%;
                aspect-ratio: 1;
                border-radius: 100%;
                background-color: ${theme.color.gray};
                position: relative;
                left: 0;
                transition: left ${theme.duration.short};
            }

            &.is-enabled:before {
                left: calc(50% + ${padding});
            }

            &.mode-default.is-enabled:before {
                background-color: ${theme.color.success};
            }

            &.mode-direction:before {
                background-color: ${theme.color.info};
            }
        }

    `;
}