import {PageRegistry} from '@webaker/app';
import {Provider} from '@webaker/package-deps';
import {createNotFoundPageOptions} from '../not-found-page-options';
import {createUnauthorizedPageOptions} from '../unauthorized-page-options';

export interface ProvidedErrorPageClientDeps {

}

export interface RequiredErrorPageClientDeps {
    pageRegistry: PageRegistry;
}

export interface ErrorPageClientDeps extends ProvidedErrorPageClientDeps, RequiredErrorPageClientDeps {

}

export interface ErrorPageClientConfig {

}

export type ErrorPageServerProvider = Provider<ErrorPageClientDeps, ErrorPageClientConfig>;

export function createErrorPageClientProvider(): ErrorPageServerProvider {

    return {

        registerServices: async ({resolve}) => {
            const pageRegistry = resolve('pageRegistry');
            const notFoundPageOptions = createNotFoundPageOptions();
            const unauthorizedPageOptions = createUnauthorizedPageOptions();
            pageRegistry.registerPageType(notFoundPageOptions);
            pageRegistry.registerPageType(unauthorizedPageOptions);
        }

    };
}