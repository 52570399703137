import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type ModalButtonsClassName = 'modalButtons';

export interface ModalButtonsCSSProps extends CSSThemeProps {

}

export function ModalButtonsCSS({theme}: ModalButtonsCSSProps) {
    return css<ModalButtonsClassName>`

        .modalButtons {
            display: flex;
            flex-direction: row;
            justify-content: stretch;
            gap: ${theme.spacing.medium};
            margin: ${theme.spacing.large} 0 0 auto;
            max-width: 400px;

            & > * {
                flex: 1;
            }
        }

    `;
}