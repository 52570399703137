import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type FileSearchClassName = 'fileSearch' | 'icon' | 'textField' | 'is-active';

export interface FileSearchCSSProps extends CSSThemeProps {

}

export function FileSearchCSS({theme}: FileSearchCSSProps) {
    return css<FileSearchClassName>`

        .fileSearch {
            display: flex;
            align-items: center;
            gap: ${theme.spacing.medium};
            width: 400px;
        }

        .icon {
            color: ${theme.color.light};
            font-size: ${theme.fontSize.large};
        }

        .is-active .icon {
            color: ${theme.color.dark};
        }

        .textField {
            flex: 1;
        }

        .is-active .textField {
            border-color: ${theme.color.dark} !important;
        }

    `;
}