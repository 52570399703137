import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';
import {color} from '@webaker/package-utils';
import {SideMenuDirection} from './side-menu';

export type SideMenuClassName = 'sideMenu' | `direction-${SideMenuDirection}`;

export function SideMenuCSS({theme}: CSSThemeProps) {
    return css<SideMenuClassName>`

        .sideMenu {
            width: 100%;
            display: flex;
            background: linear-gradient(to bottom, ${color(theme.color.veryLight, {alpha: 0.7})}, transparent);
        }

        .direction-row {
            flex-direction: row;
            height: 40px;
        }

        .direction-column {
            flex-direction: column;
            height: 100%;
        }

    `;
}