import {createAdminUserClientProvider} from '@webaker/module-admin-user/client';
import {createCommonPageClientProvider} from '@webaker/module-common-page/client';
import {createErrorPageClientProvider} from '@webaker/module-error-page/client';
import {createFileManagerPageClientProvider} from '@webaker/module-file-manager-page/client';
import {createHomePageClientProvider} from '@webaker/module-home-page/client';
import {createLoginPageClientProvider} from '@webaker/module-login-page/client';
import {createPagesManagerPageClientProvider} from '@webaker/module-pages-manager-page/client';
import {createSlotComponentClientProvider} from '@webaker/module-slot-component/client';
import {createThemeComponentClientProvider} from '@webaker/module-theme-component/client';

export type ModulesClientAppProvider = ReturnType<typeof getModulesClientAppProviders>[number];

export function getModulesClientAppProviders() {
    return [
        createAdminUserClientProvider(),
        createCommonPageClientProvider(),
        createErrorPageClientProvider(),
        createFileManagerPageClientProvider(),
        createHomePageClientProvider(),
        createLoginPageClientProvider(),
        createPagesManagerPageClientProvider(),
        createSlotComponentClientProvider(),
        createThemeComponentClientProvider()
    ] as const;
}