import {MouseEvent, useCallback, useRef} from 'react';
import {useThemedCSS} from '@webaker/package-css-theme';
import {useDependency} from '@webaker/package-deps';
import {File, FileExtension} from '@webaker/package-file';
import {useStore} from '@webaker/package-store';
import {MdIcon} from '@webaker/package-ui';
import {mergeClassNames} from '@webaker/package-utils';
import {FileManagerStore} from '../../file-manager-store';
import {DirectoryListItem} from './directory-list-item';
import {FileListItem} from './file-list-item';
import {NodeListContentCSS} from './node-list-content-css';

export interface NodeListContentProps {
    extensions?: FileExtension[];
}

export interface NodeListContentDeps {
    fileManagerStore: FileManagerStore;
}

export function NodeListContent({extensions}: NodeListContentProps) {

    const css = useThemedCSS(NodeListContentCSS);
    const fileManagerStore = useStore(useDependency<NodeListContentDeps>()('fileManagerStore'));
    const directories = fileManagerStore.getFilteredDirectories();
    const files = fileManagerStore.getFilteredFiles();
    const isLoading = fileManagerStore.isLoading();
    const elementRef = useRef<HTMLDivElement | null>(null);
    const query = fileManagerStore.getQuery();
    const noNodes = directories.length + files.length === 0;

    const handleClick = useCallback((event: MouseEvent) => {
        if (event.target === elementRef.current) {
            fileManagerStore.deselectAllNodes();
        }
    }, []);

    const isExtensionAllowed = useCallback((file: File) => {
        return extensions?.some((extension) => {
            return file.name.endsWith(`.${extension}`);
        }) ?? true;
    }, [extensions]);

    return (
        <div ref={elementRef}
             className={css['fileListContent']}
             onClick={handleClick}>

            {directories.map((directory) => {
                return (
                    <DirectoryListItem key={directory.id}
                                       directory={directory}/>
                );
            })}

            {files.map((file) => {
                return (
                    <FileListItem key={file.id}
                                  file={file}
                                  muted={!isExtensionAllowed(file)}/>
                );
            })}

            {isLoading ? (
                <div className={css['overlay']}>
                    <MdIcon name="go_to_line"
                            className={mergeClassNames(css['icon'], css['is-spinning'])}/>
                    Loading
                </div>
            ) : noNodes && query ? (
                <div className={css['overlay']}>
                    <MdIcon name="search"
                            className={css['icon']}/>
                    No results
                </div>
            ) : noNodes ? (
                <div className={css['overlay']}>
                    <MdIcon name="folder_open"
                            className={css['icon']}/>
                    Empty directory
                </div>
            ) : null}

        </div>
    );

}