import {useEffect, useState} from 'react';
import {useDependency} from '@webaker/package-deps';
import {File, FileApi} from '@webaker/package-file';
import {Button, Modal, ModalButtons, ModalInfo} from '@webaker/package-ui';

export interface FileUsageModalProps {
    file: File;
    onClose: () => void;
}

export interface FileUsageModalDeps {
    fileApi: FileApi;
}

export function FileUsageModal({file, onClose}: FileUsageModalProps) {

    const fileApi = useDependency<FileUsageModalDeps>()('fileApi');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [fileUsages, setFileUsages] = useState<string[]>([]);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            setFileUsages(await fileApi.getFileUsages(file.id));
            setIsLoading(false);
        })();
    }, [file.id]);

    return (
        <Modal title="File usage"
               onEnter={onClose}
               onEscape={onClose}>

            {isLoading ? (
                <ModalInfo>
                    Loading...
                </ModalInfo>
            ) : fileUsages.length > 0 ? (
                <ModalInfo>
                    File "{file.name}" is used by:
                    <ul>
                        {fileUsages.map((usage: string, index: number) => (
                            <li key={index}>{usage}</li>
                        ))}
                    </ul>
                </ModalInfo>
            ) : (
                <ModalInfo>
                    File "{file.name}" is not used anywhere.
                </ModalInfo>
            )}

            <ModalButtons>
                <Button color="success"
                        style="text"
                        onClick={onClose}>
                    Ok
                </Button>
            </ModalButtons>

        </Modal>
    );

}