import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';
import {TabGroupDirection} from './tab-group';

export type TabGroupClassName = 'tabGroup' | `direction-${TabGroupDirection}`;

export interface TabGroupCSSProps extends CSSThemeProps {

}

export function TabGroupCSS({theme}: TabGroupCSSProps) {
    return css<TabGroupClassName>`

        .tabGroup {
            position: relative;
            display: flex;
            margin: ${theme.spacing.large} 0;
            justify-content: space-evenly;
        }

        .direction-horizontal {
            width: 100%;
            flex-direction: row;
            border-top: 1px solid ${theme.color.light};
        }

        .direction-vertical {
            flex-direction: column;
            border-left: 1px solid ${theme.color.light};
        }

    `;
}