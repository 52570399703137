import {css} from '@webaker/package-css';
import {CSSThemeColor, CSSThemeProps} from '@webaker/package-css-theme';
import {contrast} from '@webaker/package-utils';

export type TooltipClassName = 'tooltip' | 'wrapper';

export interface TooltipCSSProps extends CSSThemeProps {

}

export function TooltipCSS({theme}: TooltipCSSProps) {

    const arrowSize = '5px';

    return css<TooltipClassName>`

        .wrapper {

        }

        .tooltip {
            margin: ${arrowSize} 0 0;
            border-radius: ${theme.roundness.medium};
            padding: ${theme.spacing.medium};
            position: absolute;
            display: flex;
            gap: ${theme.spacing.small};
            pointer-events: none;
            animation: enter ${theme.duration.short};
            white-space: nowrap;
            font-size: ${theme.fontSize.small};

            @keyframes enter {
                0% {
                    opacity: 0;
                    margin-top: -${arrowSize};
                }
                100% {
                    opacity: 1;
                    margin-top: ${arrowSize};
                }
            }

            &:before {
                content: ' ';
                width: 0;
                height: 0;
                position: absolute;
                top: -${arrowSize};
                left: ${theme.spacing.medium};
                border-style: solid;
                border-width: 0 ${arrowSize} ${arrowSize} ${arrowSize};
                border-color: transparent;
            }
        }

    `;

}

export interface TooltipPrivateCSSProps extends CSSThemeProps {
    color: CSSThemeColor;
}

export function TooltipPrivateCSS({theme, color}: TooltipPrivateCSSProps) {

    return css<TooltipClassName>`

        .tooltip {
            color: ${contrast(theme.color[color])};
            background-color: ${theme.color[color]};

            &:before {
                border-bottom-color: ${theme.color[color]};
            }
        }

    `;

}