import {Directory, DirectoryTree, File, FSNode} from '@webaker/package-file';
import {Store, StoreFactory} from '@webaker/package-store';
import {pluralize} from '@webaker/package-utils';
import {FILE_CLIPBOARD_COPY, FILE_CLIPBOARD_CUT, FileClipboard, FileClipboardType} from './file-clipboard';

export const FILE_MANAGER_STORE_NAME = 'fileManager';

export interface FileManagerStoreState {
    directoryTree: DirectoryTree;
    activeNodeId: FSNode['id'] | null;
    selectedNodesIds: FSNode['id'][];
    editedNodeId: FSNode['id'] | null;
    clipboard: FileClipboard;
    isOpen: boolean;
    isLoading: boolean;
    query: string | null;
}

export interface FileManagerStore extends Store<FileManagerStoreState> {

    name: typeof FILE_MANAGER_STORE_NAME;

    isOpen: () => boolean;
    getWorkingDirectory: () => Directory | null;
    getBreadcrumbs: () => Directory[];
    getSortedNodes: () => FSNode[];
    getSortedFiles: () => File[];
    getSortedDirectories: () => Directory[];
    getFilteredNodes: () => FSNode[];
    getFilteredFiles: () => File[];
    getFilteredDirectories: () => Directory[];
    getDirectoryByName: (directoryName: string) => Directory | null;
    getFileByName: (fileName: string) => File | null;
    getSelectedNodes: () => FSNode[];
    getSelectedDirectories: () => Directory[];
    getSelectedFiles: () => File[];
    getSelectedFilesIds: () => File['id'][];
    getSelectedNodesText: () => string;
    isNodeSelected: (nodeId: FSNode['id']) => boolean;
    isNodeEdited: (nodeId: FSNode['id']) => boolean;
    isLoading: () => boolean;
    getQuery: () => string | null;
    getClipboard: () => FileClipboard;
    getClipboardNodesText: () => string;
    canPasteNodes: () => boolean;

    openDirectory: (directoryTree: DirectoryTree | null) => void;
    addFile: (file: File) => void;
    addDirectory: (directory: Directory) => void;
    updateFile: (file: File) => void;
    updateDirectory: (directory: Directory) => void;
    deleteFile: (fileId: File['id']) => void;
    deleteDirectory: (directoryId: Directory['id']) => void;
    selectNode: (nodeId: FSNode['id']) => void;
    selectNodes: (nodesIds: FSNode['id'][]) => void;
    toggleSelectNode: (nodeId: FSNode['id']) => void;
    selectNodesRange: (nodeId: FSNode['id']) => void;
    selectAllNodes: () => void;
    deselectAllNodes: () => void;
    editNode: (nodeId: FSNode['id']) => void;
    stopEditingNode: () => void;
    startLoading: () => void;
    stopLoading: () => void;
    setQuery: (query: string | null) => void;
    copyNodes: () => void;
    cutNodes: () => void;
    clearClipboard: () => void;

}

export interface FileManagerStoreDeps {
    storeFactory: StoreFactory;
}

export function createFileManagerStore({storeFactory}: FileManagerStoreDeps): FileManagerStore {

    const getSortedNodes = (state: FileManagerStoreState): FSNode[] => {
        return [
            ...getSortedDirectories(state),
            ...getSortedFiles(state)
        ];
    };

    const getSortedFiles = (state: FileManagerStoreState): File[] => {
        return [...state.directoryTree.content.files].sort((fileA: File, fileB: File): number => {
            return fileA.name.localeCompare(fileB.name, undefined, {numeric: true});
        });
    };

    const getSortedDirectories = (state: FileManagerStoreState): Directory[] => {
        return [...state.directoryTree.content.directories].sort((directoryA: Directory, directoryB: Directory): number => {
            return directoryA.name.localeCompare(directoryB.name, undefined, {numeric: true});
        });
    };

    const getFilteredFiles = (state: FileManagerStoreState): File[] => {
        return getSortedFiles(state).filter((file: File): boolean => {
            return file.name.toLowerCase().includes(state.query?.toLowerCase() ?? '');
        });
    };

    const getFilteredDirectories = (state: FileManagerStoreState): Directory[] => {
        return getSortedDirectories(state).filter((directory: Directory): boolean => {
            return directory.name.toLowerCase().includes(state.query?.toLowerCase() ?? '');
        });
    };

    const getSelectedNodes = (state: FileManagerStoreState): FSNode[] => {
        return state.selectedNodesIds.map((id: FSNode['id']) => {
            return state.directoryTree.content.files.find((file: File): boolean => {
                return file.id === id;
            }) ?? state.directoryTree.content.directories.find((directory: Directory): boolean => {
                return directory.id === id;
            }) ?? null;
        }).filter(Boolean) as FSNode[];
    };

    const getSelectedDirectories = (state: FileManagerStoreState): Directory[] => {
        return state.selectedNodesIds.map((id: FSNode['id']) => {
            return state.directoryTree.content.directories.find((directory: Directory): boolean => {
                return directory.id === id;
            }) ?? null;
        }).filter(Boolean) as Directory[];
    };

    const getSelectedFiles = (state: FileManagerStoreState): File[] => {
        return state.selectedNodesIds.map((id: FSNode['id']) => {
            return state.directoryTree.content.files.find((file: File): boolean => {
                return file.id === id;
            }) ?? null;
        }).filter(Boolean) as File[];
    };

    const initClipboard = (state: FileManagerStoreState, type: FileClipboardType): FileManagerStoreState => {
        return {
            ...state,
            clipboard: {
                type,
                filesIds: getSelectedFiles(state).map((file: File): File['id'] => {
                    return file.id;
                }),
                directoriesIds: getSelectedDirectories(state).map((directory: Directory): Directory['id'] => {
                    return directory.id;
                }),
                parentDirectoryId: state.directoryTree.directory?.id ?? null,
                ancestorDirectoriesIds: state.directoryTree.breadcrumbs.map((directory: Directory): Directory['id'] => {
                    return directory.id;
                })
            }
        };
    };

    const getNodesText = (filesCount: number, directoriesCount: number): string => {
        if (filesCount > 0 && directoriesCount > 0) {
            return `${pluralize(filesCount, 'file', 'files')} and ${pluralize(directoriesCount, 'directory', 'directories')}`;
        }
        if (filesCount > 0) {
            return pluralize(filesCount, 'file', 'files');
        }
        if (directoriesCount > 0) {
            return pluralize(directoriesCount, 'directory', 'directories');
        }
        return '';
    };

    return storeFactory.createStore<FileManagerStore>({

        name: FILE_MANAGER_STORE_NAME,

        state: {
            directoryTree: {
                directory: null,
                content: {
                    files: [],
                    directories: []
                },
                breadcrumbs: []
            },
            activeNodeId: null,
            selectedNodesIds: [],
            editedNodeId: null,
            clipboard: {
                type: null,
                filesIds: [],
                directoriesIds: [],
                parentDirectoryId: null,
                ancestorDirectoriesIds: []
            },
            isOpen: false,
            isLoading: false,
            query: null
        },

        getters: {

            isOpen: ({state}): boolean => {
                return state.isOpen;
            },

            getWorkingDirectory: ({state}): Directory | null => {
                return state.directoryTree.directory;
            },

            getBreadcrumbs: ({state}): Directory[] => {
                return state.directoryTree.breadcrumbs;
            },

            getSortedNodes: ({state, memo}): FSNode[] => {
                return memo(() => {
                    return getSortedNodes(state);
                }, [state.directoryTree.content.files, state.directoryTree.content.directories]);
            },

            getSortedFiles: ({state, memo}): File[] => {
                return memo(() => {
                    return getSortedFiles(state);
                }, [state.directoryTree.content.files]);
            },

            getSortedDirectories: ({state, memo}): Directory[] => {
                return memo(() => {
                    return getSortedDirectories(state);
                }, [state.directoryTree.content.directories]);
            },

            getFilteredNodes: ({state, memo}): FSNode[] => {
                return memo(() => {
                    return [
                        ...getFilteredDirectories(state),
                        ...getFilteredFiles(state)
                    ];
                }, [state.directoryTree.content.files, state.directoryTree.content.directories, state.query]);
            },

            getFilteredFiles: ({state, memo}): File[] => {
                return memo(() => {
                    return getFilteredFiles(state);
                }, [state.directoryTree.content.files, state.query]);
            },

            getFilteredDirectories: ({state, memo}): Directory[] => {
                return memo(() => {
                    return getFilteredDirectories(state);
                }, [state.directoryTree.content.directories, state.query]);
            },

            getSelectedNodes: ({state, memo}): FSNode[] => {
                return memo(() => {
                    return getSelectedNodes(state);
                }, [state.selectedNodesIds, state.directoryTree.content.files, state.directoryTree.content.directories]);
            },

            getSelectedDirectories: ({state, memo}): Directory[] => {
                return memo(() => {
                    return getSelectedDirectories(state);
                }, [state.selectedNodesIds, state.directoryTree.content.directories]);
            },

            getSelectedFiles: ({state, memo}): File[] => {
                return memo(() => {
                    return getSelectedFiles(state);
                }, [state.selectedNodesIds, state.directoryTree.content.files]);
            },

            getSelectedFilesIds: ({state}): File['id'][] => {
                return getSelectedFiles(state).map((file: File): File['id'] => {
                    return file.id;
                });
            },

            getSelectedNodesText: ({state}): string => {
                const selectedFilesCount = getSelectedFiles(state).length;
                const selectedDirectoriesCount = getSelectedDirectories(state).length;
                return getNodesText(selectedFilesCount, selectedDirectoriesCount);
            },

            getFileByName: ({state}, fileName: string): File | null => {
                return state.directoryTree.content.files.find((file: File) => {
                    return file.name === fileName;
                }) ?? null;
            },

            getDirectoryByName: ({state}, directoryName: string): Directory | null => {
                return state.directoryTree.content.directories.find((directory: Directory) => {
                    return directory.name === directoryName;
                }) ?? null;
            },

            isNodeSelected: ({state}, nodeId: FSNode['id']): boolean => {
                return state.selectedNodesIds.includes(nodeId);
            },

            isNodeEdited: ({state}, nodeId: FSNode['id']): boolean => {
                return state.editedNodeId === nodeId;
            },

            isLoading: ({state}): boolean => {
                return state.isLoading;
            },

            getQuery: ({state}): string | null => {
                return state.query;
            },

            getClipboard: ({state}): FileClipboard => {
                return state.clipboard;
            },

            getClipboardNodesText: ({state}): string => {
                const clipboardFilesCount = state.clipboard.filesIds.length;
                const clipboardDirectoriesCount = state.clipboard.directoriesIds.length;
                return getNodesText(clipboardFilesCount, clipboardDirectoriesCount);
            },

            canPasteNodes: ({state}): boolean => {
                if (!state.clipboard.type) {
                    return false;
                }
                const currentDirectoriesIds = [
                    ...state.directoryTree.breadcrumbs.map((directory: Directory): Directory['id'] => {
                        return directory.id;
                    }),
                    ...(state.directoryTree.directory ? [state.directoryTree.directory.id] : [])
                ];
                return state.clipboard.directoriesIds.every((directoryId: Directory['id']): boolean => {
                    return !currentDirectoriesIds.includes(directoryId);
                });
            }

        },

        setters: {

            openDirectory: ({state}, directoryTree: DirectoryTree | null) => {
                return {
                    ...state,
                    directoryTree: directoryTree ?? {
                        directory: null,
                        content: {
                            files: [],
                            directories: []
                        },
                        breadcrumbs: []
                    },
                    selectedNodesIds: [],
                    activeNodeId: null,
                    editedNodeId: null,
                    isOpen: !!directoryTree
                };
            },

            addFile: ({state}, file: File) => {
                const alreadyExists = state.directoryTree.content.files.some((existingFile: File): boolean => {
                    return existingFile.id === file.id;
                });
                if (alreadyExists) {
                    return state;
                }
                return {
                    ...state,
                    directoryTree: {
                        ...state.directoryTree,
                        content: {
                            ...state.directoryTree.content,
                            files: [
                                ...state.directoryTree.content.files,
                                file
                            ]
                        }
                    }
                };
            },

            addDirectory: ({state}, directory: Directory) => {
                const alreadyExists = state.directoryTree.content.directories.some((existingDirectory: Directory): boolean => {
                    return existingDirectory.id === directory.id;
                });
                if (alreadyExists) {
                    return state;
                }
                return {
                    ...state,
                    directoryTree: {
                        ...state.directoryTree,
                        content: {
                            ...state.directoryTree.content,
                            directories: [
                                ...state.directoryTree.content.directories,
                                directory
                            ]
                        }
                    }
                };
            },

            updateFile: ({state}, file: File) => {
                return {
                    ...state,
                    directoryTree: {
                        ...state.directoryTree,
                        content: {
                            ...state.directoryTree.content,
                            files: state.directoryTree.content.files.map((stateFile: File): File => {
                                if (stateFile.id === file.id) {
                                    return file;
                                }
                                return stateFile;
                            })
                        }
                    }
                };
            },

            updateDirectory: ({state}, directory: Directory) => {
                return {
                    ...state,
                    directoryTree: {
                        ...state.directoryTree,
                        content: {
                            ...state.directoryTree.content,
                            directories: state.directoryTree.content.directories.map((stateDirectory: Directory): Directory => {
                                if (stateDirectory.id === directory.id) {
                                    return directory;
                                }
                                return stateDirectory;
                            })
                        }
                    }
                };
            },

            deleteFile: ({state}, fileId: File['id']) => {
                return {
                    ...state,
                    directoryTree: {
                        ...state.directoryTree,
                        content: {
                            ...state.directoryTree.content,
                            files: state.directoryTree.content.files.filter((file: File): boolean => {
                                return file.id !== fileId;
                            })
                        }
                    }
                };
            },

            deleteDirectory: ({state}, directoryId: Directory['id']) => {
                return {
                    ...state,
                    directoryTree: {
                        ...state.directoryTree,
                        content: {
                            ...state.directoryTree.content,
                            directories: state.directoryTree.content.directories.filter((directory: Directory): boolean => {
                                return directory.id !== directoryId;
                            })
                        }
                    }
                };
            },

            selectNode: ({state}, nodeId: FSNode['id']) => {
                return {
                    ...state,
                    activeNodeId: nodeId,
                    selectedNodesIds: [nodeId],
                };
            },

            selectNodes: ({state}, nodesIds: FSNode['id'][]) => {
                return {
                    ...state,
                    activeNodeId: null,
                    selectedNodesIds: nodesIds
                };
            },

            toggleSelectNode: ({state}, nodeId: FSNode['id']) => {
                return {
                    ...state,
                    activeNodeId: nodeId,
                    selectedNodesIds: state.selectedNodesIds.includes(nodeId) ? state.selectedNodesIds.filter((selectedElementId: string) => {
                        return selectedElementId !== nodeId;
                    }) : [
                        ...state.selectedNodesIds,
                        nodeId
                    ]
                };
            },

            selectNodesRange: ({state}, nodeId: FSNode['id']) => {
                const sortedNodesIds = getSortedNodes(state).map((node: FSNode): FSNode['id'] => {
                    return node.id;
                });
                const indexOfActiveNode = state.activeNodeId ? sortedNodesIds.indexOf(state.activeNodeId) : -1;
                const indexOfNode = sortedNodesIds.indexOf(nodeId);
                return indexOfActiveNode === -1 || indexOfNode === -1 ? {
                    ...state,
                    activeNodeId: nodeId,
                    selectedNodesIds: [nodeId],
                } : {
                    ...state,
                    selectedNodesIds: sortedNodesIds.slice(
                        Math.min(indexOfActiveNode, indexOfNode),
                        Math.max(indexOfActiveNode, indexOfNode) + 1
                    )
                };
            },

            selectAllNodes: ({state}) => {
                const allNodes = getSortedNodes(state);
                return {
                    ...state,
                    selectedNodesIds: allNodes.map((node: FSNode): FSNode['id'] => {
                        return node.id;
                    }),
                    activeNodeId: allNodes[0]?.id ?? null
                };
            },

            deselectAllNodes: ({state}) => {
                return {
                    ...state,
                    selectedNodesIds: [],
                    activeNodeId: null
                };
            },

            editNode: ({state}, nodeId: FSNode['id']) => {
                return {
                    ...state,
                    editedNodeId: nodeId
                };
            },

            stopEditingNode: ({state}) => {
                return {
                    ...state,
                    editedNodeId: null
                };
            },

            startLoading: ({state}) => {
                return {
                    ...state,
                    isLoading: true
                };
            },

            stopLoading: ({state}) => {
                return {
                    ...state,
                    isLoading: false
                };
            },

            setQuery: ({state}, query: string | null) => {
                return {
                    ...state,
                    query
                };
            },

            copyNodes: ({state}) => {
                return initClipboard(state, FILE_CLIPBOARD_COPY);
            },

            cutNodes: ({state}) => {
                return initClipboard(state, FILE_CLIPBOARD_CUT);
            },

            clearClipboard: ({state}) => {
                return {
                    ...state,
                    clipboard: {
                        type: null,
                        filesIds: [],
                        directoriesIds: [],
                        parentDirectoryId: null,
                        ancestorDirectoriesIds: []
                    }
                };
            }

        }

    });

}