import {useCallback, useRef} from 'react';
import {useThemedCSS} from '@webaker/package-css-theme';
import {useDependency} from '@webaker/package-deps';
import {useStore} from '@webaker/package-store';
import {MdIcon, TextField, TextFieldChangeEvent} from '@webaker/package-ui';
import {mergeClassNames, useHotkey} from '@webaker/package-utils';
import {FileManagerStore} from '../file-manager-store';
import {FileSearchCSS} from './file-search-css';

export interface FileSearchProps {

}

export interface FileSearchDeps {
    fileManagerStore: FileManagerStore;
}

export function FileSearch({}: FileSearchProps) {

    const css = useThemedCSS(FileSearchCSS);
    const fileManagerStore = useStore(useDependency<FileSearchDeps>()('fileManagerStore'));
    const query = fileManagerStore.getQuery();
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleQueryChange = useCallback((event: TextFieldChangeEvent) => {
        fileManagerStore.setQuery(event.value);
    }, []);

    useHotkey('Control+F', () => {
        inputRef.current?.focus();
    });

    return (
        <div className={mergeClassNames(css['fileSearch'], query && css['is-active'])}>
            <MdIcon name="search"
                    className={css['icon']}/>
            <TextField value={query}
                       onChange={handleQueryChange}
                       className={css['textField']}
                       inputRef={inputRef}/>
        </div>
    );
}