import {PageTypeOptions} from '@webaker/app';
import {User, UserRole} from '@webaker/package-user';
import {PAGES_MANAGER_PAGE_TYPE, PagesManagerPage} from './pages-manager-page';

export interface PagesManagerPageOptions extends PageTypeOptions<PagesManagerPage> {

}

export interface PagesManagerPageOptionsConfig {
    adminRoleId: UserRole['id'];
}

export function createPagesManagerPageOptions({adminRoleId}: PagesManagerPageOptionsConfig): PagesManagerPageOptions {
    return {

        type: PAGES_MANAGER_PAGE_TYPE,

        static: true,

        ssr: false,

        view: async () => (await import('@webaker/module-pages-manager-page/editor')).PagesManagerPageView,

        authorizer: async (_page: PagesManagerPage, user: User | null): Promise<boolean> => {
            return !!user?.rolesIds.includes(adminRoleId);
        }

    };
}