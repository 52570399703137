import {getAppMode, PRODUCTION} from '../utils/app-mode';

export interface FeaturesConfig {
    encodeState: boolean;
    minifyCSS: boolean;
}

export function getFeaturesConfig(): FeaturesConfig {

    const appMode = getAppMode();
    const encodeState = appMode === PRODUCTION;
    const minifyCSS = appMode === PRODUCTION;

    return {
        encodeState,
        minifyCSS
    };

}