import {Page} from '../page/page';
import {PagesTree} from './pages-tree';

export interface PagesTreeSelector {
    getPages: (state: PagesTree) => Page[];
    getPageById: (state: PagesTree, pageId: Page['id']) => Page | null;
    getPageByPath: (state: PagesTree, path: string) => Page | null;
    getChildPages: (state: PagesTree, pageId: Page['id']) => Page[];
    getParentPage: (state: PagesTree, pageId: Page['id']) => Page | null;
    getAncestorPages: (state: PagesTree, pageId: Page['id']) => Page[];
    getRootPages: (state: PagesTree) => Page[];
    getIsModification: (state: PagesTree) => boolean;
}

export interface PagesTreeSelectorDeps {

}

export function createPagesTreeSelector({}: PagesTreeSelectorDeps = {}): PagesTreeSelector {

    const sortByIndex = (pageA: Page, pageB: Page) => {
        if (pageA.index === undefined && pageB.index === undefined) {
            return 0;
        }
        if (pageA.index === undefined) {
            return 1;
        }
        if (pageB.index === undefined) {
            return -1;
        }
        return pageA.index - pageB.index;
    };

    const getPages = (state: PagesTree): Page[] => {
        return state.pages;
    };

    const getPageById = (state: PagesTree, pageId: Page['id']): Page | null => {
        return state.pages.find((page: Page): boolean => {
            return page.id === pageId;
        }) ?? null;
    };

    const getPageByPath = (state: PagesTree, path: string): Page | null => {
        return state.pages.find((page: Page): boolean => {
            return page.path === path;
        }) ?? null;
    };

    const getChildPages = (state: PagesTree, pageId: Page['id']): Page[] => {
        const page = getPageById(state, pageId);
        return state.pages.filter((statePage: Page) => {
            return page?.id === statePage.parentId;
        }).sort(sortByIndex);
    };

    const getParentPage = (state: PagesTree, pageId: Page['id']): Page | null => {
        const page = getPageById(state, pageId);
        return state.pages.find((statePage) => {
            return page?.parentId === statePage.id;
        }) ?? null;
    };

    const getAncestorPages = (state: PagesTree, pageId: Page['id']): Page[] => {
        const ancestorPages: Page[] = [];
        let parentPage = getParentPage(state, pageId);
        while (parentPage !== null) {
            ancestorPages.unshift(parentPage);
            parentPage = getParentPage(state, parentPage.id);
        }
        return ancestorPages;
    };

    const getRootPages = (state: PagesTree): Page[] => {
        return state.pages.filter((page) => {
            return !page.parentId;
        }).sort(sortByIndex);
    };

    const getIsModification = (state: PagesTree): boolean => {
        return state.isModification;
    };

    return {
        getPages,
        getPageById,
        getPageByPath,
        getChildPages,
        getParentPage,
        getAncestorPages,
        getRootPages,
        getIsModification
    };

}