import {PageTypeOptions} from '@webaker/app';
import {User, UserRole} from '@webaker/package-user';
import {FILE_MANAGER_PAGE_TYPE, FileManagerPage} from './file-manager-page';

export interface FileManagerPageOptions extends PageTypeOptions<FileManagerPage> {

}

export interface FileManagerPageOptionsConfig {
    adminRoleId: UserRole['id'];
}

export function createFileManagerPageOptions({adminRoleId}: FileManagerPageOptionsConfig): FileManagerPageOptions {
    return {

        type: FILE_MANAGER_PAGE_TYPE,

        static: true,

        ssr: false,

        view: async () => (await import('@webaker/module-file-manager-page/editor')).FileManagerPageView,

        authorizer: async (_page: FileManagerPage, user: User | null): Promise<boolean> => {
            return !!user?.rolesIds.includes(adminRoleId);
        }
        
    };
}