import {CSSThemeSize, useThemedCSSBundle} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {ReactNode} from 'react';
import {PanelCSS, PanelPrivateCSS} from './panel-css';

export interface PanelProps {
    title?: ReactNode;
    subtitle?: ReactNode;
    icon?: ReactNode;
    buttons?: ReactNode;
    margin?: CSSThemeSize;
    shadow?: boolean;
    children?: ReactNode;
    className?: string;
}

export function Panel({
    title,
    subtitle,
    icon,
    buttons,
    margin = 'medium',
    shadow,
    children,
    className
}: PanelProps) {

    const css = useThemedCSSBundle([
        PanelCSS,
        PanelPrivateCSS
    ], {
        margin
    });

    return (
        <section className={mergeClassNames(
            css['panel'],
            shadow && css['has-shadow'],
            title && css['has-title'],
            className
        )}>
            {title && (
                <header className={css['header']}>
                    <div className={css['title']}>
                        {icon}
                        {title}
                    </div>
                    {subtitle && (
                        <div className={css['subtitle']}>
                            {subtitle}
                        </div>
                    )}
                    {buttons && (
                        <div className={css['buttons']}>
                            {buttons}
                        </div>
                    )}
                </header>
            )}
            {children && (
                <div className={css['content']}>
                    {children}
                </div>
            )}
        </section>
    );

}