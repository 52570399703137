import {PageTypeOptions} from '@webaker/app';
import {PUBLIC_PAGE_TYPE, PublicPage} from './public-page';

export interface PublicPageOptions extends PageTypeOptions<PublicPage> {

}

export function createPublicPageOptions(): PublicPageOptions {
    return {
        type: PUBLIC_PAGE_TYPE
    };
}