import {Fragment, useCallback} from 'react';
import {useDependency} from '@webaker/package-deps';
import {Directory, File, FileApi, FSNode} from '@webaker/package-file';
import {useStore} from '@webaker/package-store';
import {Button, MdIcon, Tooltip, useToast} from '@webaker/package-ui';
import {useBooleanState, useSafeHotkey} from '@webaker/package-utils';
import {FILE_CLIPBOARD_COPY, FILE_CLIPBOARD_CUT} from '../../file-clipboard';
import {FileManagerStore} from '../../file-manager-store';
import {PasteFileErrorModal} from '../modals/paste-file-error-modal';

export interface PasteFileButtonProps {

}

export interface PasteFileButtonDeps {
    fileApi: FileApi;
    fileManagerStore: FileManagerStore;
}

export function PasteFileButton({}: PasteFileButtonProps) {

    const fileApi = useDependency<PasteFileButtonDeps>()('fileApi');
    const fileManagerStore = useStore(useDependency<PasteFileButtonDeps>()('fileManagerStore'));
    const [isErrorModalOpen, openErrorModal, closeErrorModal] = useBooleanState(false);
    const isClipboardEmpty = fileManagerStore.getClipboard().type === null;
    const clipboardNodesText = fileManagerStore.getClipboardNodesText();
    const toast = useToast();

    const pasteFiles = useCallback(async () => {
        if (fileManagerStore.getClipboard().type === null) {
            return;
        }
        if (!fileManagerStore.canPasteNodes()) {
            openErrorModal();
            return;
        }
        fileManagerStore.startLoading();
        const workingDirectoryId = fileManagerStore.getWorkingDirectory()?.id ?? null;
        const clipboard = fileManagerStore.getClipboard();
        const newDirectories = [];
        for (const directoryId of clipboard.directoriesIds) {
            const newDirectory = clipboard.type === FILE_CLIPBOARD_COPY
                ? await fileApi.copyDirectory(directoryId, workingDirectoryId)
                : await fileApi.moveDirectory(directoryId, workingDirectoryId);
            if (newDirectory) {
                newDirectories.push(newDirectory);
            }
        }
        const newFiles = [];
        for (const fileId of clipboard.filesIds) {
            const newFile = clipboard.type === FILE_CLIPBOARD_COPY
                ? await fileApi.copyFile(fileId, workingDirectoryId)
                : await fileApi.moveFile(fileId, workingDirectoryId);
            if (newFile) {
                newFiles.push(newFile);
            }
        }
        newDirectories.forEach((directory: Directory) => {
            fileManagerStore.addDirectory(directory);
        });
        newFiles.forEach((file: File) => {
            fileManagerStore.addFile(file);
        });
        const newNodesIds = [...newDirectories, ...newFiles].map((node: FSNode): FSNode['id'] => {
            return node.id;
        });
        fileManagerStore.selectNodes(newNodesIds);
        if (clipboard.type === FILE_CLIPBOARD_CUT) {
            fileManagerStore.clearClipboard();
        }
        fileManagerStore.stopLoading();
        const selectedNodesText = fileManagerStore.getSelectedNodesText();
        toast(`${selectedNodesText} has been pasted`);
    }, []);

    useSafeHotkey('Control+V', pasteFiles);

    return (
        <Fragment>
            <Tooltip title={`Paste ${clipboardNodesText}`}
                     color={isClipboardEmpty ? 'gray' : 'dark'}>
                <Button color="info"
                        style="text"
                        icon={<MdIcon name="content_paste"/>}
                        onClick={pasteFiles}
                        disabled={isClipboardEmpty}/>
            </Tooltip>
            {isErrorModalOpen && (
                <PasteFileErrorModal onClose={closeErrorModal}/>
            )}
        </Fragment>
    );

}