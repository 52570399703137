import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';
import {color, contrast} from '@webaker/package-utils';
import {SidePanelPosition} from './side-panel';

export type SidePanelClassName = 'sidePanel' | 'wrapper' | 'switch' | 'is-open' | `position-${SidePanelPosition}`;

export interface SidePanelCSSProps extends CSSThemeProps {

}

export function SidePanelCSS({theme}: SidePanelCSSProps) {

    return css<SidePanelClassName>`

        .sidePanel {
            --size: 0;
            position: relative;
            transition-duration: ${theme.duration.medium};
            width: 0;
            flex-shrink: 0;
            z-index: ${theme.zIndex.front};
        }

        .wrapper {
            background: ${theme.color.white};
            color: ${contrast(theme.color.white)};
            height: 100vh;
            position: fixed;
            top: 0;
            transition-duration: ${theme.duration.medium};
            width: calc(var(--size) * 1px);
            overflow: visible;
        }

        .switch {
            align-items: center;
            background: ${theme.color.dark};
            color: ${theme.color.black};
            cursor: pointer;
            display: flex;
            height: 40px;
            justify-content: center;
            position: absolute;
            bottom: 0;
            transition: background-color ${theme.duration.short};
            width: 40px;
            z-index: ${theme.zIndex.back};

            &:hover {
                background-color: ${color(theme.color.gray, {alpha: 0.2})};
            }
        }

        .is-open {
            width: calc(var(--size) * 1px);
        }

        .is-open .wrapper {
            box-shadow: 0 0 10px ${color(theme.color.black, {alpha: 0.2})};
        }

        .position-left .wrapper {
            left: calc(var(--size) * -1px);
            border-right: 1px solid ${theme.color.muted};
        }

        .position-right .wrapper {
            right: calc(var(--size) * -1px);
            border-left: 1px solid ${theme.color.muted};
        }

        .is-open.position-left .wrapper {
            left: 0;
        }

        .is-open.position-right .wrapper {
            right: 0;
        }

        .position-left .switch {
            left: 100%;
        }

        .position-right .switch {
            right: 100%;
        }

    `;
}