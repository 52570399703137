import {PageTypeOptions} from '@webaker/app';
import {User, UserRole} from '@webaker/package-user';
import {PRIVATE_PAGE_TYPE, PrivatePage} from './private-page';

export interface PrivatePageOptions extends PageTypeOptions<PrivatePage> {

}

export interface PrivatePageOptionsConfig {
    adminRoleId: UserRole['id'];
}

export function createPrivatePageOptions({adminRoleId}: PrivatePageOptionsConfig): PrivatePageOptions {
    return {
        type: PRIVATE_PAGE_TYPE,
        authorizer: async (_page: PrivatePage, user: User | null): Promise<boolean> => {
            return !!user?.rolesIds.includes(adminRoleId);
        }
    };
}