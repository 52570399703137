import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';
import {color} from '@webaker/package-utils';

export type NodeListItemClassName = 'nodeListItem' | 'iconColumn' | 'nameColumn' | 'sizeColumn' | 'accessColumn' | 'is-selected' | 'is-muted';

export interface NodeListItemCSSProps extends CSSThemeProps {

}

export function NodeListItemCSS({theme}: NodeListItemCSSProps) {
    return css<NodeListItemClassName>`

        .nodeListItem {
            display: flex;
            padding: ${theme.spacing.medium};
            cursor: default;
            user-select: none;
            transition: background-color ${theme.duration.short};

            &:nth-child(even) {
                background-color: ${color(theme.color.gray, {alpha: 0.1})};
            }

            &:hover, &.is-selected {
                background-color: ${color(theme.color.info, {alpha: 0.3})};
                color: ${color(theme.color.info, {lightness: 30})};

                &:nth-child(even) {
                    background-color: ${color(theme.color.info, {alpha: 0.4})};
                }
            }

            &.is-muted {
                opacity: 0.5;
            }
        }

        .iconColumn, .nameColumn, .sizeColumn, .accessColumn {
            display: flex;
            flex-direction: row;
            gap: ${theme.spacing.medium};
            justify-content: center;
        }

        .iconColumn {
            width: 50px;
        }

        .nameColumn {
            width: auto;
            flex: 1;
            justify-content: flex-start;
        }

        .sizeColumn {
            width: 100px;
            text-align: center;
        }

        .accessColumn {
            width: 100px;
            text-align: center;
        }

    `;
}