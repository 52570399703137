import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';
import {color} from '@webaker/package-utils';

export type NodeListClassName = 'nodeList';

export interface NodeListCSSProps extends CSSThemeProps {

}

export function NodeListCSS({theme}: NodeListCSSProps) {
    return css<NodeListClassName>`

        .nodeList {
            border: 1px solid ${theme.color.light};
            border-radius: ${theme.roundness.medium};
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            min-height: 0;
            box-shadow: 0 0 40px ${color(theme.color.black, {alpha: 0.1})};
        }

    `;
}