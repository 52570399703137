import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';
import {color} from '@webaker/package-utils';

export type ColorPaletteClassName =
    'colorPalette' |
    'svPalette' |
    'hPalette' |
    'svPicker' |
    'hPicker' |
    'aPalette' |
    'aPicker' |
    'eyeDropper' |
    'input';

export interface ColorPaletteCSSProps extends CSSThemeProps {

}

export function ColorPaletteCSS({theme}: ColorPaletteCSSProps) {
    return css<ColorPaletteClassName>`

        .colorPalette {
            --color: #fff;
            --color-full-a: #fff;
            --color-full-sva: #fff;
            width: 300px;
            height: 300px;
            font-size: 1rem;
            background-color: ${theme.color.white};
            border-radius: ${theme.roundness.medium};
            box-shadow: 0 3px 2px ${color(theme.color.black, {alpha: 0.2})};
            padding: ${theme.spacing.medium};
            gap: ${theme.spacing.medium};
            display: grid;
            grid-template-columns: 1.8fr 0.2fr;
            grid-template-rows: 1.8fr 0.2fr;
            grid-template-areas: "SL H" "A E";

            &:has(.input) {
                grid-template-rows: 2em 1.8fr 0.2fr;
                grid-template-areas: "I I" "SL H" "A E";
                height: calc(300px + 2em);
            }
        }

        .svPalette {
            width: 100%;
            height: 100%;
            background-image: linear-gradient(to bottom, transparent, #000),
            linear-gradient(to right, #fff, transparent),
            linear-gradient(var(--color-full-sva), var(--color-full-sva));
            border-radius: ${theme.roundness.medium};
            position: relative;
            grid-area: SL;
        }

        .svPicker {
            position: absolute;
            pointer-events: none;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background: var(--color);
            box-shadow: 0 0 1px 1px #000, 0 0 2px 2px #fff;
            transform: translate(-50%, -50%);
        }

        .hPalette {
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, #f00, #f0f, #00f, #0ff, #0f0, #ff0, #f00);
            border-radius: ${theme.roundness.medium};
            position: relative;
            grid-area: H;
        }

        .hPicker {
            position: absolute;
            pointer-events: none;
            width: 100%;
            height: 5px;
            border-radius: 1px;
            box-shadow: 0 0 1px 1px #000, 0 0 2px 2px #fff;
            transform: translate(0, -50%);
            background: var(--color-full-sva);
        }

        .aPalette {
            width: 100%;
            height: 100%;
            border-radius: ${theme.roundness.medium};
            position: relative;
            grid-area: A;
            background-image: linear-gradient(to right, transparent, var(--color-full-a)),
            repeating-linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee),
            repeating-linear-gradient(45deg, #eee 25%, #fff 25%, #fff 75%, #eee 75%, #eee);
            background-position: 0 0, 0 0, 8px 8px;
            background-size: 100%, 16px 16px, 16px 16px;
        }

        .aPicker {
            position: absolute;
            pointer-events: none;
            width: 5px;
            height: 100%;
            border-radius: 1px;
            box-shadow: 0 0 1px 1px #000, 0 0 2px 2px #fff;
            transform: translate(-50%, 0);
            background-image: linear-gradient(var(--color), var(--color)),
            repeating-linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee),
            repeating-linear-gradient(45deg, #eee 25%, #fff 25%, #fff 75%, #eee 75%, #eee);
            background-position: 0 0, 0 0, 8px 8px;
            background-size: 100%, 16px 16px, 16px 16px;
        }

        .eyeDropper {
            grid-area: E;
            font-size: ${theme.fontSize.small};
        }

        .input {
            grid-area: I;
            padding: ${theme.spacing.medium};
            border: none;
            outline: none;
            font-weight: bold;
            background: none;

            &::placeholder {
                color: ${theme.color.light};
            }
        }

    `;
}