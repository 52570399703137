import React from 'react';
import {useDependency} from '@webaker/package-deps';
import {ExtensionManager} from './extension-manager';

export const EXTENSIONS_ELEMENT_ID: string = 'extensions';

export interface ExtensionsInjectorProps {

}

export interface ExtensionsInjectorDeps {
    extensionManager: ExtensionManager;
}

export function ExtensionsInjector({}: ExtensionsInjectorProps) {

    const extensionManager = useDependency<ExtensionsInjectorDeps>()('extensionManager');

    return (
        <script type="application/json"
                dangerouslySetInnerHTML={{__html: JSON.stringify(extensionManager.getEnabledExtensionsNames())}}
                id={EXTENSIONS_ELEMENT_ID}/>
    );

}