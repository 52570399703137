import {Component} from './component';
import {ComponentExtensionNotFoundError} from './component-extension-not-found-error';
import {ComponentExtensionOptions} from './component-extension-options';
import {ComponentTypeNotFoundError} from './component-type-not-found-error';
import {ComponentTypeOptions} from './component-type-options';

export interface ComponentRegistry {
    registerComponentType: <C extends Component = Component>(options: ComponentTypeOptions<C>) => void;
    getAllComponentsTypes: () => Component['type'][];
    getAllComponentsTypesOptions: () => ComponentTypeOptions[];
    getComponentTypeOptions: <C extends Component = Component>(type: C['type']) => ComponentTypeOptions<C>;

    registerComponentExtension: <C extends Component = Component>(options: ComponentExtensionOptions<C>) => void;
    getAllComponentsExtensionsOptions: () => ComponentExtensionOptions[];
    getComponentExtensionOptions: <C extends Component = Component>(name: string) => ComponentExtensionOptions<C>;
}

export interface ComponentRegistryDeps {

}

export function createComponentRegistry({}: ComponentRegistryDeps = {}): ComponentRegistry {

    const componentsTypesOptionsMap: Map<string, ComponentTypeOptions<any>> = new Map();
    const componentsExtensionsOptionsMap: Map<string, ComponentExtensionOptions<any>> = new Map();

    const registerComponentType = <C extends Component = Component>(options: ComponentTypeOptions<C>): void => {
        componentsTypesOptionsMap.set(options.type, options);
    };

    const getAllComponentsTypes = (): Component['type'][] => {
        return Array.from(componentsTypesOptionsMap.keys());
    };

    const getComponentTypeOptions = <C extends Component = Component>(type: C['type']): ComponentTypeOptions<C> => {
        if (!componentsTypesOptionsMap.has(type)) {
            throw new ComponentTypeNotFoundError(`Component type [${type}] not found`, {type});
        }
        return componentsTypesOptionsMap.get(type)!;
    };

    const getAllComponentsTypesOptions = (): ComponentTypeOptions[] => {
        return Array.from(componentsTypesOptionsMap.values());
    };

    const registerComponentExtension = <C extends Component = Component>(options: ComponentExtensionOptions<C>): void => {
        componentsExtensionsOptionsMap.set(options.name, options);
    };

    const getAllComponentsExtensionsOptions = (): ComponentExtensionOptions[] => {
        return Array.from(componentsExtensionsOptionsMap.values());
    };

    const getComponentExtensionOptions = <C extends Component = Component>(name: string): ComponentExtensionOptions<C> => {
        if (!componentsExtensionsOptionsMap.has(name)) {
            throw new ComponentExtensionNotFoundError(`Component extension [${name}] not found`, {name});
        }
        return componentsExtensionsOptionsMap.get(name)!;
    };

    return {
        registerComponentExtension,
        getAllComponentsTypes,
        getAllComponentsTypesOptions,
        getComponentTypeOptions,
        registerComponentType,
        getAllComponentsExtensionsOptions,
        getComponentExtensionOptions
    };

}