import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {ReactElement, ReactNode} from 'react';
import {IconItemCSS} from './icon-item-css';

export interface IconItemProps {
    icon: ReactElement;
    title: ReactNode;
    subtitle?: ReactNode;
    inline?: number;
    className?: string;
    onClick?: () => void;
}

export function IconItem({icon, title, subtitle, inline, className, onClick}: IconItemProps) {
    const css = useThemedCSS(IconItemCSS);
    return (
        <div onClick={onClick}
             className={mergeClassNames(
                 css['iconItem'],
                 inline && css['is-inline'],
                 onClick && css['is-clickable'],
                 className
             )}
             style={{
                 width: inline ? `calc(100% / ${inline})` : ''
             }}>
            <div className={css['icon']}>
                {icon}
            </div>
            <div className={css['info']}>
                <div className={css['title']}>
                    {title}
                </div>
                {subtitle && (
                    <div className={css['subtitle']}>
                        {subtitle}
                    </div>
                )}
            </div>
        </div>
    );
}