import {HTMLTemplate} from './html-template';
import {HTMLTemplateInjector} from './html-template-injector';

export interface HTMLTemplateRegistry {
    registerInjector: (htmlTemplateInjector: HTMLTemplateInjector) => void;
    runInjectors: (htmlTemplate: HTMLTemplate) => void;
}

export function createHTMLTemplateRegistry(): HTMLTemplateRegistry {

    const htmlTemplateInjectors: HTMLTemplateInjector[] = [];

    const registerInjector = (htmlTemplateInjector: HTMLTemplateInjector) => {
        htmlTemplateInjectors.push(htmlTemplateInjector);
    };

    const runInjectors = (htmlTemplate: HTMLTemplate): void => {
        htmlTemplateInjectors.forEach((injector: HTMLTemplateInjector): void => {
            injector.inject(htmlTemplate);
        });
    };

    return {
        registerInjector,
        runInjectors
    };

}