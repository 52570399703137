export interface ExtensionAlreadyRegisteredErrorDetails {
    name: string;
}

export class ExtensionAlreadyRegisteredError extends Error {

    name = 'EXTENSION_ALREADY_REGISTERED';

    details: ExtensionAlreadyRegisteredErrorDetails;

    constructor(message: string, details: ExtensionAlreadyRegisteredErrorDetails) {
        super(message);
        this.details = details;
    }

}