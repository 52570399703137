import {CSSTheme} from '@webaker/package-css-theme';

export interface CSSThemeConfig {
    defaultCSSTheme: Omit<CSSTheme, 'id'>;
}

export function getCSSThemeConfig(): CSSThemeConfig {

    const black = '#000';
    const veryDark = '#111';
    const dark = '#333';
    const gray = '#888';
    const light = '#ccc';
    const veryLight = '#eee';
    const white = '#fff';
    const red = '#d35';
    const pink = '#b49';
    const purple = '#64d';
    const indigo = '#35c';
    const blue = '#08d';
    const cyan = '#1bc';
    const green = '#4a7';
    const lime = '#ad5';
    const yellow = '#fd4';
    const orange = '#e60';
    const brown = '#742';

    return {
        defaultCSSTheme: {

            name: 'Default',

            color: {
                primary: yellow,
                secondary: orange,
                danger: red,
                success: green,
                info: blue,
                muted: gray,
                marked: red,
                black,
                veryDark,
                dark,
                gray,
                light,
                veryLight,
                white,
                red,
                pink,
                purple,
                indigo,
                blue,
                cyan,
                green,
                lime,
                yellow,
                orange,
                brown
            },

            spacing: {
                none: '0',
                verySmall: '0.2em',
                small: '0.4em',
                medium: '0.6em',
                large: '1em',
                veryLarge: '2em'
            },

            fontSize: {
                none: '0',
                verySmall: '0.5em',
                small: '0.75em',
                medium: '1em',
                large: '1.25em',
                veryLarge: '1.75em'
            },

            roundness: {
                none: '0',
                verySmall: '1px',
                small: '3px',
                medium: '5px',
                large: '10px',
                veryLarge: '20px'
            },

            duration: {
                none: '0ms',
                veryShort: '10ms',
                short: '100ms',
                medium: '200ms',
                long: '1000ms',
                veryLong: '2000ms'
            },

            zIndex: {
                veryBack: 1,
                back: 2,
                middle: 3,
                front: 4,
                veryFront: 5
            },

            mediaQuery: {
                phone: '(max-width: 767px)',
                tablet: '(min-width: 768px) and (max-width: 1023px)',
                desktop: '(min-width: 1024px)',
            }

        }
    };

}