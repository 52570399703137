import {Page} from './page';
import {PageExtensionNotFoundError} from './page-extension-not-found-error';
import {PageExtensionOptions} from './page-extension-options';
import {PageTypeNotFoundError} from './page-type-not-found-error';
import {PageTypeOptions} from './page-type-options';

export interface PageRegistry {

    registerPageType: <P extends Page = Page>(options: PageTypeOptions<P>) => void;
    getAllPagesTypes: () => Page['type'][];
    getAllPagesTypesOptions: () => PageTypeOptions[];
    getPageTypeOptions: <P extends Page = Page>(type: P['type']) => PageTypeOptions<P>;

    registerPageExtension: <P extends Page = Page>(options: PageExtensionOptions<P>) => void;
    getAllPagesExtensionsOptions: () => PageExtensionOptions[];
    getPageExtensionOptions: <P extends Page = Page>(name: string) => PageExtensionOptions<P>;

}

export interface PageRegistryDeps {

}

export function createPageRegistry({}: PageRegistryDeps = {}): PageRegistry {

    const pagesTypesOptionsMap: Map<string, PageTypeOptions<any>> = new Map();
    const pagesExtensionsOptionsMap: Map<string, PageExtensionOptions<any>> = new Map();

    const registerPageType = <P extends Page = Page>(options: PageTypeOptions<P>): void => {
        pagesTypesOptionsMap.set(options.type, options);
    };

    const getAllPagesTypes = (): Page['type'][] => {
        return Array.from(pagesTypesOptionsMap.keys());
    };

    const getAllPagesTypesOptions = (): PageTypeOptions[] => {
        return Array.from(pagesTypesOptionsMap.values());
    };

    const getPageTypeOptions = <P extends Page = Page>(type: P['type']): PageTypeOptions<P> => {
        if (!pagesTypesOptionsMap.has(type)) {
            throw new PageTypeNotFoundError(`Page type [${type}] not found`, {type});
        }
        return pagesTypesOptionsMap.get(type)!;
    };

    const registerPageExtension = <P extends Page = Page>(options: PageExtensionOptions<P>): void => {
        pagesExtensionsOptionsMap.set(options.name, options);
    };

    const getAllPagesExtensionsOptions = (): PageExtensionOptions[] => {
        return Array.from(pagesExtensionsOptionsMap.values());
    };

    const getPageExtensionOptions = <P extends Page = Page>(name: string): PageExtensionOptions<P> => {
        if (!pagesExtensionsOptionsMap.has(name)) {
            throw new PageExtensionNotFoundError(`Page extension [${name}] not found`, {name});
        }
        return pagesExtensionsOptionsMap.get(name)!;
    };

    return {
        registerPageType,
        getAllPagesTypes,
        getAllPagesTypesOptions,
        getPageTypeOptions,
        registerPageExtension,
        getAllPagesExtensionsOptions,
        getPageExtensionOptions
    };

}