import {MouseEvent, ReactNode} from 'react';
import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {NodeListItemCSS} from './node-list-item-css';

export interface NodeListItemProps {
    iconColumn?: ReactNode;
    nameColumn?: ReactNode;
    sizeColumn?: ReactNode;
    accessColumn?: ReactNode;
    onClick?: (event: MouseEvent) => void;
    onDoubleClick?: (event: MouseEvent) => void;
    selected?: boolean;
    muted?: boolean;
    className?: string;
    children?: ReactNode;
}

export function NodeListItem({
    iconColumn,
    nameColumn,
    sizeColumn,
    accessColumn,
    onClick,
    onDoubleClick,
    selected,
    muted,
    className,
    children
}: NodeListItemProps) {

    const css = useThemedCSS(NodeListItemCSS);

    return (
        <div onClick={onClick}
             onDoubleClick={onDoubleClick}
             className={mergeClassNames(
                 css['nodeListItem'],
                 selected && css['is-selected'],
                 muted && css['is-muted'],
                 className
             )}>
            <div className={css['iconColumn']}>
                {iconColumn}
            </div>
            <div className={css['nameColumn']}>
                {nameColumn}
            </div>
            <div className={css['sizeColumn']}>
                {sizeColumn}
            </div>
            <div className={css['accessColumn']}>
                {accessColumn}
            </div>
            {children}
        </div>
    );

}