import {useThemedCSS} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {ReactNode} from 'react';
import {SidePanelCSS} from './side-panel-css';

export interface SidePanelProps {
    position: SidePanelPosition;
    size: number;
    open: boolean;
    children?: ReactNode;
    className?: string;
}

export type SidePanelPosition = 'left' | 'right';

export function SidePanel({position, size, open, children, className}: SidePanelProps) {

    const css = useThemedCSS(SidePanelCSS);

    return (
        <aside className={mergeClassNames(
            css['sidePanel'],
            open && css['is-open'],
            css[`position-${position}`]
        )} style={{'--size': size}}>
            <div className={mergeClassNames(css['wrapper'], className)}>
                {children}
            </div>
        </aside>
    );

}