import {createApiClientProvider} from '@webaker/package-api/client';
import {createCSSThemeClientProvider} from '@webaker/package-css-theme/client';
import {createCSSClientProvider} from '@webaker/package-css/client';
import {createEventBusClientProvider} from '@webaker/package-event-bus/client';
import {createExtensionClientProvider} from '@webaker/package-extension/client';
import {createFileClientProvider} from '@webaker/package-file/client';
import {createFlexBoxClientProvider} from '@webaker/package-flex-box/client';
import {createHTMLTemplateClientProvider} from '@webaker/package-html-template/client';
import {createImageFileClientProvider} from '@webaker/package-image-file/client';
import {createJSXClientProvider} from '@webaker/package-jsx/client';
import {createStoreClientProvider} from '@webaker/package-store/client';
import {createThemeClientProvider} from '@webaker/package-theme/client';
import {createUserClientProvider} from '@webaker/package-user/client';
import {createUtilsClientProvider} from '@webaker/package-utils/client';
import {createValidationClientProvider} from '@webaker/package-validation/client';

export type PackagesClientAppProvider = ReturnType<typeof getPackagesClientAppProviders>[number];

export function getPackagesClientAppProviders() {
    return [
        createApiClientProvider(),
        createCSSClientProvider(),
        createCSSThemeClientProvider(),
        createExtensionClientProvider(),
        createEventBusClientProvider(),
        createFileClientProvider(),
        createFlexBoxClientProvider(),
        createHTMLTemplateClientProvider(),
        createImageFileClientProvider(),
        createJSXClientProvider(),
        createStoreClientProvider(),
        createThemeClientProvider(),
        createUserClientProvider(),
        createUtilsClientProvider(),
        createValidationClientProvider()
    ] as const;
}