import {ApiClientFactory} from '@webaker/package-api/client';
import {PAGES_MANAGER_PAGE_API_NAME, PagesManagerPageApi} from '../pages-manager-page-api';

export interface PagesManagerPageClientApiDeps {
    apiClientFactory: ApiClientFactory;
}

export function createPagesManagerPageClientApi({apiClientFactory}: PagesManagerPageClientApiDeps): PagesManagerPageApi {
    return apiClientFactory.createApi<PagesManagerPageApi>(PAGES_MANAGER_PAGE_API_NAME, {
        savePages: ['pages']
    });
}