export interface ParseNumberOptions {
    min?: number;
    max?: number;
    step?: number;
    default?: number;
}

const PRECISION = 12;

export function parseNumber(value: number | string, options: ParseNumberOptions = {}): number {
    const {
        min = -Infinity,
        max = Infinity,
        step = 0
    } = options;
    const number = typeof value === 'number' ? value : parseFloat(value);
    if (isNaN(number)) {
        return Math.max(min, Math.min(max, options.default ?? 0));
    }
    const roundedNumber = step === 0 ? number : parseFloat((Math.round(number / step) * step).toPrecision(PRECISION));
    return Math.max(min, Math.min(max, roundedNumber));
}