import {useCallback} from 'react';
import {useDependency} from '@webaker/package-deps';
import {File} from '@webaker/package-file';
import {useStore} from '@webaker/package-store';
import {Button, MdIcon, Tooltip} from '@webaker/package-ui';
import {download} from '@webaker/package-utils';
import {FileManagerStore} from '../../file-manager-store';

export interface DownloadFileButtonProps {

}

export interface DownloadFileButtonDeps {
    fileManagerStore: FileManagerStore;
}

export function DownloadFileButton({}: DownloadFileButtonProps) {

    const fileManagerStore = useStore(useDependency<DownloadFileButtonDeps>()('fileManagerStore'));
    const selectedFiles = fileManagerStore.getSelectedFiles();
    const isAnyFileSelected = selectedFiles.length > 0;

    const downloadFiles = useCallback(() => {
        selectedFiles.forEach((file: File): void => {
            if (file.url) {
                download(file.url, file.name);
            }
        });
    }, [selectedFiles]);

    return (
        <Tooltip title="Download"
                 color={isAnyFileSelected ? 'dark' : 'gray'}>
            <Button color="info"
                    style="text"
                    icon={<MdIcon name="download"/>}
                    onClick={downloadFiles}
                    disabled={!isAnyFileSelected}/>
        </Tooltip>
    );

}