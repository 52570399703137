import {Page} from './page';
import {PageAuthorizer} from './page-authorizer';
import {AsyncPageView} from './page-view';

export interface PageTypeOptions<P extends Page = Page> {
    type: P['type'];
    static?: boolean;
    ssr?: boolean;
    view?: AsyncPageView<P>;
    authorizer?: PageAuthorizer<P>;
}

export const DEFAULT_PAGE_TYPE_OPTIONS_SSR = true;