import {CSSThemeSize, useThemedCSSBundle} from '@webaker/package-css-theme';
import {mergeClassNames} from '@webaker/package-utils';
import {ReactNode} from 'react';
import {LabelCSS, LabelPrivateCSS} from './label-css';

export interface LabelProps {
    margin?: CSSThemeSize;
    active?: boolean;
    disabled?: boolean;
    children?: ReactNode;
    className?: string;
}

export function Label({
    margin = 'none',
    active,
    disabled,
    children,
    className
}: LabelProps) {

    const css = useThemedCSSBundle([
        LabelCSS,
        LabelPrivateCSS
    ], {
        margin
    });

    return (
        <div className={mergeClassNames(
            css['label'],
            active && css['is-active'],
            disabled && css['is-disabled'],
            className
        )}>
            {children}
        </div>
    );

}