import {ApiClientFactory} from '@webaker/package-api/client';
import {Provider} from '@webaker/package-deps';
import {ExtensionChangeEvent} from '@webaker/package-extension';
import {ExtensionApi} from '../extension-api';
import {ExtensionManager} from '../extension-manager';
import {createExtensionClientApi} from './extension-client-api';

export interface ProvidedExtensionClientDeps {
    extensionApi: ExtensionApi;
}

export interface RequiredExtensionClientDeps {
    apiClientFactory: ApiClientFactory;
    extensionManager: ExtensionManager;
}

export interface ExtensionClientDeps extends ProvidedExtensionClientDeps, RequiredExtensionClientDeps {

}

export interface ExtensionClientConfig {

}

export type ExtensionClientProvider = Provider<ExtensionClientDeps, ExtensionClientConfig>;

export function createExtensionClientProvider(): ExtensionClientProvider {
    return {

        registerDependencies: async ({register}) => {
            register('extensionApi', ({resolve}) => {
                return createExtensionClientApi({
                    apiClientFactory: resolve('apiClientFactory')
                });
            });
        },

        registerServices: async ({resolve}) => {
            const extensionManager = resolve('extensionManager');
            const extensionApi = resolve('extensionApi');
            extensionManager.addChangeListener(async ({name, isEnabled}: ExtensionChangeEvent): Promise<void> => {
                if (isEnabled) {
                    await extensionApi.enableExtension(name);
                } else {
                    await extensionApi.disableExtension(name);
                }
            });
        }

    };
}