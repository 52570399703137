import {ApiClientFactory} from '@webaker/package-api/client';
import {Provider} from '@webaker/package-deps';
import {FileStore} from '@webaker/package-file';
import {StoreFactory, StoreRegistry} from '@webaker/package-store';
import {IdGenerator} from '@webaker/package-utils';
import {createThemeFactory, ThemeFactory} from '../theme-factory';
import {createThemeRenderer, ThemeRenderer} from '../theme-renderer';
import {createThemeStore, ThemeStore} from '../theme-store';
import {createThemeInjector, ThemeInjector} from './theme-injector';

export interface ProvidedThemeClientDeps {
    themeInjector: ThemeInjector;
    themeFactory: ThemeFactory;
    themeRenderer: ThemeRenderer;
    themeStore: ThemeStore;
}

export interface RequiredThemeClientDeps {
    apiClientFactory: ApiClientFactory;
    fileStore: FileStore;
    idGenerator: IdGenerator;
    storeFactory: StoreFactory;
    storeRegistry: StoreRegistry;
}

export interface ThemeClientDeps extends ProvidedThemeClientDeps, RequiredThemeClientDeps {

}

export interface ThemeClientConfig {

}

export type ThemeClientProvider = Provider<ThemeClientDeps, ThemeClientConfig>;

export function createThemeClientProvider(): ThemeClientProvider {
    return {

        registerDependencies: async ({register}) => {
            register('themeInjector', ({resolve}) => {
                return createThemeInjector({
                    themeRenderer: resolve('themeRenderer'),
                    themeStore: resolve('themeStore')
                });
            });
            register('themeFactory', ({resolve}) => {
                return createThemeFactory({
                    idGenerator: resolve('idGenerator')
                });
            });
            register('themeRenderer', ({resolve}) => {
                return createThemeRenderer({
                    fileStore: resolve('fileStore')
                });
            });
            register('themeStore', ({resolve}) => {
                return createThemeStore({
                    storeFactory: resolve('storeFactory')
                });
            });
        },

        registerServices: async ({resolve}) => {
            const storeRegistry = resolve('storeRegistry');
            const themeStore = resolve('themeStore');
            storeRegistry.registerStore(themeStore);
        },

        runServices: async ({resolve}) => {
            const themeInjector = resolve('themeInjector');
            themeInjector.injectThemesAndWatch();
        }

    };
}