import {AppExtension} from '@webaker/app';
import {ContainerExtension} from '@webaker/extension-container/boot';
import {GridExtension} from '@webaker/extension-grid/boot';
import {HTMLCodeExtension} from '@webaker/extension-html-code/boot';
import {ImageExtension} from '@webaker/extension-image/boot';
import {JSCodeExtension} from '@webaker/extension-js-code/boot';
import {LinkExtension} from '@webaker/extension-link/boot';
import {MdIconExtension} from '@webaker/extension-md-icon/boot';
import {RichTextExtension} from '@webaker/extension-rich-text/boot';
import {RowAndColumnExtension} from '@webaker/extension-row-and-column/boot';
import {SchemaExtension} from '@webaker/extension-schema/boot';
import {SEOExtension} from '@webaker/extension-seo/boot';
import {SiIconExtension} from '@webaker/extension-si-icon/boot';
import {TextExtension} from '@webaker/extension-text/boot';
import {YoutubeExtension} from '@webaker/extension-youtube/boot';

export interface ExtensionsConfig {
    extensions: AppExtension[];
}

export function getExtensionsConfig(): ExtensionsConfig {
    return {
        extensions: [
            ContainerExtension,
            GridExtension,
            HTMLCodeExtension,
            ImageExtension,
            JSCodeExtension,
            LinkExtension,
            MdIconExtension,
            RichTextExtension,
            RowAndColumnExtension,
            SchemaExtension,
            SEOExtension,
            SiIconExtension,
            TextExtension,
            YoutubeExtension
        ]
    };
}