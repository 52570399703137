import {Store, StoreFactory} from '@webaker/package-store';
import {PagesTreeMutator} from '../content/pages-tree-mutator';
import {PagesTreeSelector} from '../content/pages-tree-selector';
import {Page} from './page';

export const PAGE_STORE_NAME = 'page';

export interface PageStoreState {
    pages: Page[];
    isModification: boolean;
}

export interface PageStore extends Store<PageStoreState> {
    name: typeof PAGE_STORE_NAME;
    getPages: () => Page[];
    getPageById: (pageId: Page['id']) => Page | null;
    getPageByPath: (path: string) => Page | null;
    getChildPages: (pageId: Page['id']) => Page[];
    getParentPage: (pageId: Page['id']) => Page | null;
    getAncestorPages: (pageId: Page['id']) => Page[];
    getRootPages: () => Page[];
    getIsModification: () => boolean;
    setPages: (pages: Page[]) => void;
    addPage: (page: Page) => void;
    updatePage: (page: Page) => void;
    deletePage: (pageId: Page['id']) => void;
    movePage: (pageId: Page['id'], targetPageId: Page['id']) => void;
    movePageBefore: (pageId: Page['id'], targetPageId: Page['id']) => void;
    movePageAfter: (pageId: Page['id'], targetPageId: Page['id']) => void;
    setIsModification: (isModification: boolean) => void;
}

export interface PageStoreDeps {
    storeFactory: StoreFactory;
    pagesTreeMutator: PagesTreeMutator;
    pagesTreeSelector: PagesTreeSelector;
}

export function createPageStore({storeFactory, pagesTreeMutator, pagesTreeSelector}: PageStoreDeps): PageStore {
    return storeFactory.createStore<PageStore>({

        name: PAGE_STORE_NAME,

        state: {
            pages: [],
            isModification: false
        },

        getters: {

            getPages: ({state}): Page[] => {
                return pagesTreeSelector.getPages(state);
            },

            getPageById: ({state}, pageId: Page['id']): Page | null => {
                return pagesTreeSelector.getPageById(state, pageId);
            },

            getPageByPath: ({state}, path: string): Page | null => {
                return pagesTreeSelector.getPageByPath(state, path);
            },

            getChildPages: ({state}, pageId: Page['id']): Page[] => {
                return pagesTreeSelector.getChildPages(state, pageId);
            },

            getParentPage: ({state}, pageId: Page['id']): Page | null => {
                return pagesTreeSelector.getParentPage(state, pageId);
            },

            getAncestorPages: ({state}, pageId: Page['id']): Page[] => {
                return pagesTreeSelector.getAncestorPages(state, pageId);
            },

            getRootPages: ({state}): Page[] => {
                return pagesTreeSelector.getRootPages(state);
            },

            getIsModification: ({state}): boolean => {
                return pagesTreeSelector.getIsModification(state);
            }

        },

        setters: {

            setPages: ({state}, pages: Page[]): PageStoreState => {
                return pagesTreeMutator.setPages(state, pages);
            },

            addPage: ({state}, page: Page): PageStoreState => {
                return pagesTreeMutator.addPage(state, page);
            },

            updatePage: ({state}, page: Page): PageStoreState => {
                return pagesTreeMutator.updatePage(state, page);
            },

            deletePage: ({state}, pageId: Page['id']): PageStoreState => {
                return pagesTreeMutator.deletePage(state, pageId);
            },

            movePage: ({state}, pageId: Page['id'], targetPageId: Page['id']): PageStoreState => {
                return pagesTreeMutator.movePage(state, pageId, targetPageId);
            },

            movePageBefore: ({state}, pageId: Page['id'], targetPageId: Page['id']): PageStoreState => {
                return pagesTreeMutator.movePageBefore(state, pageId, targetPageId);
            },

            movePageAfter: ({state}, pageId: Page['id'], targetPageId: Page['id']): PageStoreState => {
                return pagesTreeMutator.movePageAfter(state, pageId, targetPageId);
            },

            setIsModification: ({state}, isModification: boolean): PageStoreState => {
                return pagesTreeMutator.setIsModification(state, isModification);
            }

        }

    });
}