import {PageRegistry} from '@webaker/app';
import {ApiClientFactory} from '@webaker/package-api/client';
import {Provider} from '@webaker/package-deps';
import {UserRole} from '@webaker/package-user';
import {PagesManagerPageApi} from '../pages-manager-page-api';
import {createPagesManagerPageOptions} from '../pages-manager-page-options';
import {createPagesManagerPageClientApi} from './pages-manager-page-client-api';

export interface ProvidedPagesManagerPageClientDeps {
    pagesManagerPageApi: PagesManagerPageApi;
}

export interface RequiredPagesManagerPageClientDeps {
    apiClientFactory: ApiClientFactory;
    pageRegistry: PageRegistry;
}

export interface PagesManagerPageClientDeps extends ProvidedPagesManagerPageClientDeps, RequiredPagesManagerPageClientDeps {

}

export interface PagesManagerPageClientConfig {
    adminRoleId: UserRole['id'];
}

export type PagesManagerPageClientProvider = Provider<PagesManagerPageClientDeps, PagesManagerPageClientConfig>;

export function createPagesManagerPageClientProvider(): PagesManagerPageClientProvider {
    return {

        registerDependencies: async ({register}) => {
            register('pagesManagerPageApi', ({resolve}) => {
                return createPagesManagerPageClientApi({
                    apiClientFactory: resolve('apiClientFactory')
                });
            });
        },

        registerServices: async ({resolve}, config) => {
            const pageRegistry = resolve('pageRegistry');
            const loginPageOptions = createPagesManagerPageOptions({
                adminRoleId: config.adminRoleId
            });
            pageRegistry.registerPageType(loginPageOptions);
        }

    };
}