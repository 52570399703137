import {css} from '@webaker/package-css';
import {CSSThemeProps} from '@webaker/package-css-theme';

export type TextFieldClassName = 'textField';

export interface TextFieldCSSProps extends CSSThemeProps {

}

export function TextFieldCSS({}: TextFieldCSSProps) {
    return css<TextFieldClassName>`

        .textField {
            position: relative;
        }

    `;
}