import {useThemedCSS} from '@webaker/package-css-theme';
import {adjustColor, ColorFormat, isColor, mergeClassNames, useBooleanState, useClickOutside} from '@webaker/package-utils';
import {MutableRefObject, useCallback, useEffect, useRef, useState} from 'react';
import {ColorPalette, ColorPaletteChangeEvent} from './color-palette';
import {ColorPickerCSS} from './color-picker-css';
import {Input, InputChangeEvent} from './input';
import {MdIcon} from './md-icon';

export interface ColorPickerProps {
    value: string | null;
    onChange?: (event: ColorPickerChangeEvent) => void;
    format?: ColorFormat;
    placeholder?: string | null;
    disabled?: boolean;
    className?: string;
    inputRef?: MutableRefObject<HTMLInputElement | null>;
}

export interface ColorPickerChangeEvent {
    value: string | null;
}

const DEFAULT_COLOR = '#fff';

export function ColorPicker({
    value,
    onChange,
    format = 'hex',
    placeholder,
    disabled,
    className,
    inputRef
}: ColorPickerProps) {

    const css = useThemedCSS(ColorPickerCSS, {});
    const paletteRef = useRef<HTMLDivElement | null>(null);
    const [isPaletteOpen, openPalette, closePalette] = useBooleanState(false);
    const [localValue, setLocalValue] = useState<string | null>(() => {
        return value === null ? null : isColor(value) ? adjustColor(value, {format}) : value;
    });

    const handleInputChange = useCallback((event: InputChangeEvent) => {
        const newValue = event.value === '' ? null : event.value;
        setLocalValue(newValue);
        if (newValue === null || isColor(newValue)) {
            onChange?.({value: newValue});
        }
    }, [onChange]);

    const handlePaletteChange = useCallback((event: ColorPaletteChangeEvent) => {
        setLocalValue(event.value);
        onChange?.({value: event.value});
    }, [onChange]);

    useEffect(() => {
        setLocalValue(value === null ? null : isColor(value) ? adjustColor(value, {format}) : value);
    }, [value]);

    useClickOutside(paletteRef, () => {
        closePalette();
    });

    const isValidColor = localValue && isColor(localValue);

    const palette = isPaletteOpen ? (
        <div ref={paletteRef}>
            <ColorPalette value={localValue}
                          onChange={handlePaletteChange}
                          format={format}/>
        </div>
    ) : null;

    return (
        <Input className={mergeClassNames(css['colorPicker'], className)}
               style={{
                   '--color': isValidColor ? localValue : DEFAULT_COLOR
               }}
               value={localValue ?? ''}
               onChange={handleInputChange}
               placeholder={placeholder}
               outside={palette}
               disabled={disabled}
               inputRef={inputRef}>
            {isValidColor ? (
                <button className={css['previewButton']}
                        onClick={openPalette}
                        disabled={disabled}/>
            ) : (
                <button className={css['openButton']}
                        onClick={openPalette}
                        disabled={disabled}>
                    <MdIcon name="water_drop"/>
                </button>
            )}
        </Input>
    );

}