import {css} from '@webaker/package-css';
import {CSSThemeColor, CSSThemeProps} from '@webaker/package-css-theme';
import {adjustColor, contrast} from '@webaker/package-utils';
import {SideMenuAlignment} from './side-menu';

export type SideMenuItemClassName = 'sideMenuItem' | 'icon' | 'label' | `align-${SideMenuAlignment}` | 'is-active' | 'is-disabled';

export function SideMenuItemCSS({theme}: CSSThemeProps) {
    return css<SideMenuItemClassName>`

        .sideMenuItem {
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;
            color: inherit;
            transition: ${theme.duration.short} background-color;
            aspect-ratio: 1;
            background: none;
            border: none;

            &:not(.is-disabled, .is-active):hover {
                background-color: var(--color-a2);
            }

            &:not(.is-disabled):hover .label {
                margin-left: 10px;
                opacity: 1;
            }
        }

        .icon {
            display: inline-block;
            text-align: center;
            transition: ${theme.duration.short} opacity;
            width: 100%;
        }

        .label {
            color: ${theme.color.black};
            display: inline-block;
            font-weight: bold;
            margin: 0;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            text-shadow: 0 0 2px ${theme.color.white};
            transition: ${theme.duration.short} margin, ${theme.duration.short} opacity;
            white-space: nowrap;
        }

        .sideMenuItem:not(.is-disabled) {
            &:hover, &:focus-visible {
                .label {
                    margin: 0 10px;
                    opacity: 1;
                    outline: none;
                }
            }
        }

        .is-disabled {
            cursor: default;
            opacity: 0.5;
        }

        .is-active {
            background-color: var(--color);
            color: var(--color-contrast);
        }

        .align-left .label {
            left: 100%;
        }

        .align-right .label {
            right: 100%;
        }

    `;
}


export interface SideMenuItemPrivateCSSProps extends CSSThemeProps {
    color: CSSThemeColor;
}

export function SideMenuItemPrivateCSS({theme, color}: SideMenuItemPrivateCSSProps) {
    return css<SideMenuItemClassName>`

        .sideMenuItem {
            --color: ${theme.color[color]};
            --color-contrast: ${contrast(theme.color[color])};
            --color-a2: ${adjustColor(theme.color[color], {alpha: 0.2})};
        }

    `;
}